import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import React, { Component } from "react";
import { Container } from "reactstrap";
import FormComponent from "../FormComponent";
import Notification from "../Notification";
import {
  user,
  baseApiUrl,
  validateForm,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import DashboardNav from "../Dashboard/DashboardNav";
import styled from "@emotion/styled";
import { Redirect } from "react-router";

const MainTitle = styled.h3`
  ${({ language }) => language === "ar" && `text-align: right`}
`;

class AddCategory extends Component {
  state = {
    title: "",
    theme: "",
    layout: "",
    error: {},
    image: null,
    subtitle: "",
    goHome: false,
    categories: [],
    description: "",
    isLoading: false,
    writer: user(this.props.token).fullName,
  };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    this.setState({ isLoading: true });
    const { language } = this.props;
    try {
      const response = await axios.get(
        `${baseApiUrl()}/categories?lang=${language}`
      );
      this.setState({ categories: response.data || [], isLoading: false });
      return;
    } catch (error) {
      this.setState({ isLoading: false });
      console.log("error add cate", error);
    }
  };

  handleUpload = (data) => {
    const { image } = this.state;
    const updatedImageName = `${uuidv4()}-${image.name}`;

    const uploadTask = firebase
      .storage()
      .ref(`images/${updatedImageName}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
        this.setState({ isLoading: false });
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("images")
          .child(updatedImageName)
          .getDownloadURL()
          .then(async (url) => {
            const response = await axios.post(`${baseApiUrl()}/categories`, {
              ...data,
              imageUrl: url,
              imageName: updatedImageName,
            });
            if (response.data) {
              this.setState({ goHome: true });
            }
          });
      }
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { image, title } = this.state;

    const callBack = (error) => {
      this.setState({ error });
    };
    if (
      !validateForm({
        image,
        title,
        callBack,
        categoryValidation: true,
      })
    ) {
      return;
    }

    try {
      setAxiosAuthHeader(this.props.token);
      const data = {
        title: this.state.title,
        theme: this.state.theme,
        layout: this.state.layout,
        subtitle: this.state.subtitle,
        language: this.props.language,
        order: this.state.categories.length + 1,
        description: this.state.description,
        writer: this.state.writer || user(this.props.token).fullName,
        imageUrl: "",
        imageName: "",
      };
      this.setState({ isLoading: true });
      if (this.state.image) {
        this.handleUpload(data);
      } else {
        const response = await axios.post(`${baseApiUrl()}/categories`, data);
        if (response.data) {
          this.setState({ goHome: true });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, error: {} });
  };

  handleImage = (e) => {
    e.preventDefault();
    this.setState({ image: e.target.files[0], error: {} });
  };

  render() {
    const { error, goHome, isLoading } = this.state;
    const { language, token } = this.props;
    return (
      <>
        <DashboardNav token={token} language={language} />
        {goHome && <Redirect to="/" />}
        <Container className="my-3">
          {isLoading && <SpinnerComponent />}
          {error.title && (
            <Notification
              title={error.title}
              type={error.type}
              message={error.message}
            />
          )}
          <MainTitle language={language} className="display-5">
            {language === "ar" ? "اضافة فئة جديدة" : "Add New Category"}
          </MainTitle>
          <FormComponent
            layout="portrait"
            token={token}
            language={language}
            onSubmit={this.onSubmit}
            handleImage={this.handleImage}
            handleChange={this.handleChange}
          />
        </Container>
      </>
    );
  }
}

export default AddCategory;
