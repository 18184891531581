import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Container } from "reactstrap";
import React, { Component } from "react";
import FormComponent from "../FormComponent";
import Notification from "../Notification";
import {
  user,
  baseApiUrl,
  validateForm,
  getQueryByName,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import DashboardNav from "../Dashboard/DashboardNav";
import { Redirect } from "react-router";
import styled from "@emotion/styled";

const MainTitle = styled.h3`
  ${({ language }) => language === "ar" && `text-align: right`}
`;

class EditCategory extends Component {
  previousOrder = null;

  state = {
    title: "",
    theme: "",
    error: {},
    status: "",
    layout: "",
    writer: "",
    order: null,
    image: null,
    subtitle: "",
    language: "",
    imageUrl: "",
    imageName: "",
    goHome: false,
    description: "",
    categoryId: null,
    isLoading: false,
  };

  componentDidMount() {
    const { pathname } = window.location;
    const pathnameParts = pathname && pathname.split("/");
    const categoryId = pathnameParts && pathnameParts[pathnameParts.length - 1];
    this.fetchData(categoryId, getQueryByName("lang"));
  }

  fetchData = async (categoryId, language) => {
    this.setState({ isLoading: true });
    const { token } = this.props;

    try {
      const response = await axios.get(
        `${baseApiUrl()}/categories/${categoryId}?lang=${language}`
      );

      if (
        (user(token).userType !== "admin" &&
          response.data &&
          response.data.writer !== user(token).fullName) ||
        !response.data
      ) {
        this.setState({ goHome: true });
        return;
      }
      this.setState({
        language,
        title: response.data.title,
        theme: response.data.theme,
        writer: response.data.writer,
        order: response.data.order_column,
        layout: response.data.layout || "portrait",
        subtitle: response.data.subtitle,
        imageUrl: response.data.image_url,
        imageName: response.data.image_name,
        categoryId: response.data.category_id,
        status: response.data.category_status,
        description: response.data.description,
        isLoading: false,
      });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  updateCategory = async (data) => {
    return axios.put(`${baseApiUrl()}/categories`, data);
  };

  handleUpload = (data) => {
    const { image } = this.state;
    const updatedImageName = `${uuidv4()}-${image.name}`;

    const uploadTask = firebase
      .storage()
      .ref(`images/${updatedImageName}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
        this.setState({ isLoading: false });
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("images")
          .child(updatedImageName)
          .getDownloadURL()
          .then(async (url) => {
            await this.updateCategory({
              ...data,
              imageUrl: url,
              imageName: updatedImageName,
            })
              .then((response) => {
                if (response.data) {
                  this.setState({ goHome: true });
                }
              })
              .catch((error) => console.error(error));
          });
      }
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      image,
      title,
      theme,
      order,
      writer,
      layout,
      imageUrl,
      subtitle,
      language,
      imageName,
      categoryId,
      description,
    } = this.state;

    const callBack = (error) => {
      this.setState({ error });
    };
    if (
      !validateForm({
        image,
        title,
        order,
        validateOrder: true,
        callBack,
        categoryValidation: true,
      })
    ) {
      return;
    }

    const stateData = {
      title,
      theme,
      order,
      writer,
      layout,
      imageUrl,
      subtitle,
      language,
      imageName,
      categoryId,
      description,
      categoryStatus: this.state.status,
    };
    try {
      setAxiosAuthHeader(this.props.token);
      this.setState({ isLoading: true });
      if (image) {
        if (imageName) {
          await firebase
            .storage()
            .ref(`images/${imageName}`)
            .delete()
            .then(() => {
              this.setState({ imageName: "", imageUrl: "" });
            })
            .catch((err) => {
              console.error(err);
              if (err.message && err.message.includes("does not exist")) {
                this.setState({ isLoading: false });
              }
            });
        }
        this.handleUpload(stateData);
      } else {
        await this.updateCategory(stateData)
          .then((response) => {
            if (response.data) {
              this.setState({ goHome: true });
            }
          })
          .catch((error) => console.error(error));
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  };

  updateOrder = async (orderId) => {
    this.setState({ isLoading: true });
    const { language } = this.state;
    try {
      setAxiosAuthHeader(this.props.token);
      const response = await axios.get(
        `${baseApiUrl()}/categories/order/${orderId}?lang=${language}`
      );

      if (response.data) {
        await this.updateCategory({
          order: this.previousOrder,
          title: response.data.title,
          theme: response.data.theme,
          writer: response.data.writer,
          language: response.data.language,
          subtitle: response.data.subtitle,
          imageUrl: response.data.image_url,
          imageName: response.data.image_name,
          categoryId: response.data.category_id,
          description: response.data.description,
          categoryStatus: response.data.category_status,
        });
      }
      await this.setState({
        order: orderId,
        error: {},
        isLoading: false,
      });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    if (this.state.order) {
      this.previousOrder = this.state.order;
    }
    if (e.target.name === "order" && e.target.value) {
      this.updateOrder(e.target.value);
    } else {
      this.setState({ [e.target.name]: e.target.value, error: {} });
    }
  };

  handleImage = (e) => {
    e.preventDefault();
    this.setState({ image: e.target.files[0], error: {} });
  };

  handleRemoveImage = async (e) => {
    e.preventDefault();
    const { imageName } = this.state;
    if (imageName) {
      await firebase
        .storage()
        .ref(`images/${imageName}`)
        .delete()
        .then(() => {
          this.setState({ imageUrl: "", imageName: "", image: null });
        })
        .catch((error) => {
          if (error.message && error.message.includes("does not exist")) {
            this.setState({ imageUrl: "", imageName: "", image: null });
          }
          console.error(error);
        });
    }
  };

  render() {
    const { error, goHome, isLoading } = this.state;
    const { language, token } = this.props;

    return (
      <>
        <DashboardNav token={token} language={language} />
        {goHome && <Redirect to="/" />}
        <Container className="my-3">
          {isLoading && <SpinnerComponent />}
          {error.title && (
            <Notification
              title={error.title}
              type={error.type}
              message={error.message}
            />
          )}
          <MainTitle language={language} className="display-5">
            {language === "ar" ? "تعديل الفئة" : "Edit Category"}
          </MainTitle>
          <FormComponent
            isEditForm
            showOrderInput
            token={token}
            language={language}
            onSubmit={this.onSubmit}
            title={this.state.title}
            image={this.state.image}
            theme={this.state.theme}
            order={this.state.order}
            status={this.state.status}
            writer={this.state.writer}
            layout={this.state.layout}
            imageUrl={this.state.imageUrl}
            handleImage={this.handleImage}
            subtitle={this.state.subtitle}
            handleChange={this.handleChange}
            description={this.state.description}
            handleRemoveImage={this.handleRemoveImage}
          />
        </Container>
      </>
    );
  }
}

export default EditCategory;
