import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AddCategory from "./components/Category/AddCategory";
import AddArticle from "./components/Article/AddArticle";
import NavComponent from "./components/Nav";
import Home from "./components/Home";
import Login from "./components/Login";
import Logout from "./components/Logout";
import About from "./components/About";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Article from "./components/Article";
import WhatWeDo from "./components/WhatWeDo";
import Dashboard from "./components/Dashboard";
import Membership from "./components/Membership";
import GetInvolved from "./components/GetInvolved";
import AddHero from "./components/MainHero/AddHero";
import EditHero from "./components/MainHero/EditHero";
import HeroView from "./components/MainHero/HeroView";
import EditArticle from "./components/Article/EditArticle";
import RouteValidator from "./components/RouteValidator";
import EditAboutArticle from "./components/About/EditAboutArticle";
import EditCategory from "./components/Category/EditCategoryContents";
import AddAboutArticle from "./components/About/AddAboutArticle";
import AddWhatWeDoArticle from "./components/WhatWeDo/AddWhatWeDoArticle";
import AddGetInvolvedArticle from "./components/GetInvolved/AddGetInvolvedArticle";
import AddMembershipArticle from "./components/Membership/AddMembershipArticle";
import EditWhatWeDoArticle from "./components/WhatWeDo/EditWhatWeDoArticle";
import EditGetInvolvedArticle from "./components/GetInvolved/EditGetInvolved";
import EditMembership from "./components/Membership/EditMembership";
import Members from "./components/Membership/Members";
import Registration from "./components/Registration";
import Users from "./components/Users";
import UploadMembershipForm from "./components/UploadMembershipForm";
import Profile from "./components/Profile";
import ActivateAccount from "./components/Registration/Activate";
import ForgotPassword from "./components/Registration/ForgotPassword";
import ResetPassword from "./components/Registration/ResetPassword";

function App() {
  const [token, setToken] = useState("");
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(JSON.parse(localStorage.getItem("language")) || "en");
  }, []);
  document.dir = language === "ar" ? "rtl" : "ltr";

  return (
    <Router>
      <Banner language={language} />
      <NavComponent
        token={token}
        language={language}
        setLanguage={setLanguage}
      />
      <Switch>
        <Route exact path="/">
          <Home language={language} token={token} />
        </Route>
        <Route exact path="/contact">
          <Contact language={language} token={token} />
        </Route>
        <Route exact path="/about">
          <About language={language} token={token} />
        </Route>
        <Route path="/add-about-us-article">
          <RouteValidator token={token} roll="editor">
            <AddAboutArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-about">
          <RouteValidator token={token} roll="editor">
            <EditAboutArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/activate">
          <ActivateAccount language={language} />
        </Route>
        <Route exact path="/forgot">
          <ForgotPassword language={language} />
        </Route>
        <Route exact path="/reset">
          <ResetPassword language={language} />
        </Route>
        <Route exact path="/what-we-do">
          <WhatWeDo language={language} token={token} />
        </Route>
        <Route path="/add-what-we-do-article">
          <RouteValidator token={token} roll="editor">
            <AddWhatWeDoArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-what-we-do-article">
          <RouteValidator token={token} roll="editor">
            <EditWhatWeDoArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/get-involved">
          <GetInvolved language={language} token={token} />
        </Route>
        <Route path="/add-get-involved-article">
          <RouteValidator token={token} roll="editor">
            <AddGetInvolvedArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-get-involved-article">
          <RouteValidator token={token} roll="editor">
            <EditGetInvolvedArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/membership">
          <Membership language={language} token={token} />
        </Route>
        <Route path="/add-membership-article">
          <RouteValidator token={token} roll="editor">
            <AddMembershipArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-membership-article">
          <RouteValidator token={token} roll="editor">
            <EditMembership language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/dashboard">
          <RouteValidator token={token} roll="editor">
            <Dashboard language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/profile">
          <RouteValidator token={token} roll="all">
            <Profile language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/users">
          <RouteValidator token={token}>
            <Users language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/members">
          <RouteValidator token={token}>
            <Members language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route exact path="/login">
          <Login language={language} setToken={setToken} token={token} />
        </Route>
        <Route path="/sign-up-form">
          <Registration language={language} />
        </Route>
        <Route exact path="/logout">
          <Logout language={language} setToken={setToken} />
        </Route>
        <Route path="/add-category">
          <RouteValidator token={token} roll="editor">
            <AddCategory language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-category/:categoryId">
          <RouteValidator token={token} roll="editor">
            <EditCategory language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/add-article">
          <RouteValidator token={token} roll="editor">
            <AddArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-article/:articleId">
          <RouteValidator token={token} roll="editor">
            <EditArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/add-hero">
          <RouteValidator token={token} roll="editor">
            <AddHero language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/upload-membership-form">
          <RouteValidator token={token}>
            <UploadMembershipForm language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/add-get-involved-article">
          <RouteValidator token={token} roll="editor">
            <AddGetInvolvedArticle language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/edit-hero/:heroId">
          <RouteValidator token={token} roll="editor">
            <EditHero language={language} token={token} />
          </RouteValidator>
        </Route>
        <Route path="/hero-view/:heroId">
          <HeroView language={language} token={token} />
        </Route>
        <Route path="/article/:articleId">
          <Article language={language} token={token} />
        </Route>
      </Switch>
      <Footer language={language} token={token} />
    </Router>
  );
}

export default App;
