import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  InputGroup,
  FormFeedback,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import axios from "axios";
import styled from "@emotion/styled";
import { baseUrl } from "../../helpers";
import SpinnerComponent from "../Spinner";
import { Link, Redirect } from "react-router-dom";

const Heading = styled.h3`
  text-align: center;
`;

const LoginRow = styled(Row)`
  padding: 10px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    height: 600px;
  }
`;

const ButtonWrapper = styled(Button)`
  text-align: center;
  width: 100%;
`;

const Login = ({ language, setToken }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [goHome, setGoHome] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError({ ...error, email: "" });
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setError({ ...error, password: "" });
  };

  const validateCredential = () => {
    let isValid;
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email && !password) {
      setError({
        email:
          language === "ar" ? "البريد الالكتروني مطلوب!" : "Email is required!",
        password:
          language === "ar" ? "كلمة المرور مطلوبة!" : "Password is required!",
      });
      isValid = false;
    } else if (!email || !validEmail.test(email)) {
      setError({
        email: !email
          ? language === "ar"
            ? "البريد الالكتروني مطلوب!"
            : "Email is required!"
          : language === "ar"
          ? "يجب عليك كتابة بريد إلكتروني صالح!"
          : "You have to write a valid email!",
      });
      isValid = false;
    } else if (!password || password.length < 5) {
      setError({
        password: !password
          ? language === "ar"
            ? "كلمة المرور مطلوبة!"
            : "Password is required!"
          : language === "ar"
          ? "يجب أن يكون طول كلمة المرور أطول من 5 أحرف"
          : "Password length has to be longer than 5 characters",
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateCredential()) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl()}/auth/login?lang=${language}`,
        {
          email,
          password,
        }
      );
      if (response.data) {
        setToken(response.data.token);
        setGoHome(true);
      }
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;
      const { message } = data;
      const errorType =
        message.includes("User") || message.includes("المستخدم")
          ? { email: message }
          : { password: message };
      setError({ ...error, ...errorType });
      setIsLoading(false);
    }
  };

  return (
    <LoginRow>
      {isLoading && <SpinnerComponent />}
      {goHome && <Redirect to="/" />}
      <Col xs={{ size: 10 }} md={{ size: 4 }}>
        <Heading>{language === "ar" ? "تسجيل الدخول" : "Login"}</Heading>
        <br />
        <Form onSubmit={handleSubmit} method="post">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>@</InputGroupText>
            </InputGroupAddon>
            <Input
              required
              type="email"
              placeholder={language === "ar" ? "البريد الإلكتروني" : "email"}
              onChange={handleEmail}
              invalid={Boolean(error.email)}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <FormFeedback>{error.email}</FormFeedback>
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {language === "ar" ? "مرور" : "Pas"}
              </InputGroupText>
            </InputGroupAddon>
            <Input
              required
              type="password"
              placeholder={language === "ar" ? "كلمه السر" : "password"}
              onChange={handlePassword}
              invalid={Boolean(error.password)}
            />
            <FormFeedback>{error.password}</FormFeedback>
          </InputGroup>
          <br />
          <p style={language === "ar" ? { "text-align": "right" } : {}}>
            {language === "ar" ? (
              <span>
                اضغط <Link to="/sign-up-form">هنا</Link> لانشاء حساب
              </span>
            ) : (
              <span>
                {" "}
                If you do not have an account press{" "}
                <Link to="/sign-up-form">here</Link> to sign up
              </span>
            )}
          </p>
          <p style={language === "ar" ? { "text-align": "right" } : {}}>
            {language === "ar" ? (
              <span>
                <Link to="/forgot">هل نسيت كلمة المرور</Link>
              </span>
            ) : (
              <span>
                {" "}
                <Link to="/forgot">Forgot password?</Link>{" "}
              </span>
            )}
          </p>
          <ButtonWrapper type="submit" color="primary">
            {language === "ar" ? "إرسال" : "Submit"}
          </ButtonWrapper>
        </Form>
      </Col>
    </LoginRow>
  );
};

export default Login;
