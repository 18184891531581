import React from "react";
import styled from "@emotion/styled";

const ComponentContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
`;

const ComponentDescription = styled.p``;

const ContentsPlaceholder = ({ language }) => (
  <ComponentContainer>
    <ComponentDescription>
      {language === "ar"
        ? "لا توجد محتويات متاحة الآن"
        : "There are no contents available right now."}
    </ComponentDescription>
  </ComponentContainer>
);

export default ContentsPlaceholder;
