import axios from "axios";

const jwtDecode = require("jwt-decode");

export const setAxiosAuthHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const user = (token) => {
  if (token) {
    const userData = jwtDecode(token);
    return {
      email: userData.email,
      userType:
        process.env.REACT_APP_ADMIN_EMAIL === userData.email
          ? "admin"
          : userData.userType,
      userId: userData.userId,
      fullName: userData.fullName,
    };
  }
  return {};
};

export const adminView = (token) => {
  const adminView = localStorage.getItem("adminView");
  return user(token).userType === "admin" && JSON.parse(adminView);
};

export const editorView = (token) => {
  const adminView = localStorage.getItem("adminView");
  return user(token).userType === "editor" && JSON.parse(adminView);
};

export const validateForm = ({
  image,
  order,
  title,
  imageId,
  callBack,
  categoryId,
  description,
  validateOrder,
  validateArticle,
  categoryValidation,
}) => {
  let isValid = true;
  if (!categoryValidation && !image && !imageId) {
    callBack({
      title: "Error",
      type: "danger",
      message: "Image is required!",
    });
    isValid = false;
  }

  if (validateOrder && !order) {
    callBack({
      title: "Error",
      type: "danger",
      message: "Order is required!",
    });
    isValid = false;
  }

  if (!title) {
    callBack({
      title: "Error",
      type: "danger",
      message: "Title is required!",
    });
    isValid = false;
  }
  if (validateArticle && !categoryId) {
    callBack({
      title: "Error",
      type: "danger",
      message: "Category is required!",
    });
    isValid = false;
  }
  if (!categoryValidation && !description) {
    callBack({
      title: "Error",
      type: "danger",
      message: "Description is required!",
    });
    isValid = false;
  }
  return isValid;
};

export const baseApiUrl = () => {
  return `${process.env.REACT_APP_BASE_API_URL}/api`;
};

export const baseUrl = () => {
  return process.env.REACT_APP_BASE_API_URL;
};

export const getQueryByName = (name) => {
  const { search } = window.location;
  if (!search) {
    return "";
  }
  const queriesUrl = search.substr(1);
  const queries = queriesUrl.split("&&");
  const result = queries
    .map((query) => {
      const queryData = query.split("=");
      const queryName = queryData[0];
      const queryValue = queryData[1];
      if (queryName === name) {
        return queryValue;
      } else {
        return false;
      }
    })
    .filter(Boolean);
  return (result && result[0]) || "";
};

export const sortCallBack = (a, b) => {
  if (a.order_column < b.order_column) {
    return -1;
  }
  if (a.order_column > b.order_column) {
    return 1;
  }
  return 0;
};
