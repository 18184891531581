import React from "react";
import styled from "@emotion/styled";
import { Card, CardText, CardBody, CardLink, CardImg } from "reactstrap";
import { Link } from "react-router-dom";

const CustomCard = styled(Card)`
  ${({ layout }) =>
    layout === "landscape" &&
    `
  max-height: 155px;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 0;
`}
`;

const CustomCardImg = styled(CardImg)`
  width: 100%;
  height: 120px;
  @media (min-width: 567px) {
    height: 140px;
  }
  @media (min-width: 768px) {
    height: 180px;
  }
  @media (min-width: 992px) {
    height: 200px;
  }
  @media (min-width: 1200px) {
    height: 240px;
  }
  ${({ layout }) =>
    layout === "landscape" &&
    `
    width: 45%;
    height: 100px !important;
    border-radius: 0 !important;
    @media (min-width: 500px) {
      height: 120px !important;
    }
    @media (min-width: 768px) {
      height: 134px !important;
    }
`}
`;

const CustomCardBody = styled(CardBody)`
  padding: 8px;
  ${({ layout }) =>
    layout === "landscape" &&
    `
    width: 55%;
    font-size: 15px;

`};
`;

const StyledCardTitle = styled.h3`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 300;
  margin-bottom: 4px;
  ${({ layout }) =>
    layout === "landscape" &&
    `
  font-size: 18px;
  font-weight: 500;
`}
`;

const CardParagraph = styled(CardText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
`;

const CustomCardLink = styled(CardLink)`
  display: inline-block;
  margin-bottom: 4px;
  ${({ layout }) =>
    layout === "landscape" &&
    `
  font-size: 14px;
  font-weight: 400;
`}
`;

const ArticleCard = ({
  title,
  layout,
  language,
  imageSrc,
  imageText,
  articleId,
  description,
}) => {
  return (
    <CustomCard layout={layout}>
      <CustomCardImg layout={layout} src={imageSrc} alt={imageText} />
      <CustomCardBody layout={layout}>
        <StyledCardTitle layout={layout}>{title}</StyledCardTitle>
        <CardParagraph layout={layout} className="lead">
          {description}
        </CardParagraph>
        <CustomCardLink layout={layout} tag={Link} to={`article/${articleId}`}>
          {language === "ar" ? "المزيد" : "Find more"}
        </CustomCardLink>
      </CustomCardBody>
    </CustomCard>
  );
};

export default ArticleCard;
