import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
  Container,
} from "reactstrap";
import styled from "@emotion/styled";
import axios from "axios";
import Notification from "../Notification";
import { baseApiUrl } from "../../helpers";
const ContactContainer = styled.div`
  padding: 50px 0;
  ${({ language }) => language === "ar" && "text-align: right"}
`;

const ContactTitle = styled.h1`
  margin: 10px 0;
  padding: 0;
  font-size: 30px;
  text-align: center;
`;

const Contact = ({ language }) => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [fullName, setFullName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email && text) {
        const response = await axios.post(`${baseApiUrl()}/email`, {
          text,
          email,
          subject,
          fullName,
          language,
        });
        if (response.data) {
          setText("");
          setEmail("");
          setSubject("");
          setFullName("");
          setMessage({
            title: language === "ar" ? "اشعار" : "Notification",
            type: "success",
            message: response.data.message[language],
          });
          setTimeout(() => {
            setMessage({});
          }, 5000);
        }
      }
    } catch (error) {
      setMessage({
        title: language === "ar" ? "خطاء" : "Error",
        type: "danger",
        message:
          error.response &&
          error.response.data.message &&
          error.response.data.message[language],
      });
      console.error(error);
    }
  };

  const handleSetEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleSetText = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };
  const handleSetSubject = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };
  const handleSetFullName = (e) => {
    e.preventDefault();
    setFullName(e.target.value);
  };
  return (
    <ContactContainer language={language}>
      <Container>
        {message.title && (
          <Notification
            title={message.title}
            type={message.type}
            message={message.message}
          />
        )}
        <Row xs="1" sm="1">
          <Col
            sm={{ size: 6, offset: 3 }}
            style={language === "ar" ? { "margin-right": "25%" } : {}}
          >
            <ContactTitle>
              {language === "ar" ? "اتصل بنا" : "Contact us:"}
            </ContactTitle>
            <Form onSubmit={handleSubmit} method="post">
              <FormGroup>
                <Label for="exampleEmail">
                  {language === "ar" ? "البريد الإلكتروني" : "Email"}
                </Label>
                <Input
                  required
                  type="email"
                  name="email"
                  value={email}
                  id="exampleEmail"
                  onChange={handleSetEmail}
                  placeholder={
                    language === "ar" ? "البريد الإلكتروني" : "Email"
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label for="exampleFullName">
                  {language === "ar" ? "الاسم الكامل" : "Full Name"}
                </Label>
                <Input
                  required
                  type="text"
                  value={fullName}
                  name="fullName"
                  id="exampleFullName"
                  onChange={handleSetFullName}
                  placeholder={language === "ar" ? "الاسم الكامل" : "Full Name"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSubject">
                  {language === "ar" ? "الموضوع" : "Subject"}
                </Label>
                <Input
                  required
                  type="text"
                  name="subject"
                  value={subject}
                  id="exampleSubject"
                  onChange={handleSetSubject}
                  placeholder={language === "ar" ? "الموضوع" : "Subject"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">
                  {language === "ar" ? "المحتوي" : "Comment"}
                </Label>
                <Input
                  required
                  name="text"
                  value={text}
                  type="textarea"
                  id="exampleText"
                  onChange={handleSetText}
                  placeholder={language === "ar" ? "المحتوي" : "Comment"}
                />
              </FormGroup>
              <Button type="submit" block color="success">
                {language === "ar" ? "ارسل" : "Submit"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </ContactContainer>
  );
};

export default Contact;
