import React, { useState, useEffect } from "react";
import { Container, Button } from "reactstrap";
import styled from "@emotion/styled";
import { user } from "../../helpers";
import { FaUser } from "react-icons/fa";
import { Redirect } from "react-router";

const PersonImage = styled.div`
  margin: 0;
  padding: 20px;
  font-size: 65px;
  border-radius: 40%;
  margin-bottom: 10px;
  display: inline-block;
  border: 1px solid #000;
`;

const ProfileContainer = styled(Container)`
  padding: 10px;
`;

const ProfileDescription = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
`;

const Profile = ({ token }) => {
  const [adminView, setAdminView] = useState(false);
  const [goHome, setGoHome] = useState(false);

  useEffect(() => {
    const getAdminView = localStorage.getItem("adminView");
    setAdminView(Boolean(JSON.parse(getAdminView)));
  }, [adminView]);

  const toggleAdminView = (e) => {
    e.preventDefault();
    localStorage.setItem("adminView", JSON.stringify(!adminView));
    setAdminView(!adminView);
    setGoHome(true);
  };

  return (
    <>
      {goHome && <Redirect to="/" />}
      <ProfileContainer>
        <PersonImage>
          <FaUser />
        </PersonImage>
        <ProfileDescription>Welcome: {user(token).fullName}</ProfileDescription>
        {(user(token).userType === "admin" ||
          user(token).userType === "editor") && (
          <Button onClick={toggleAdminView} color="primary">
            Display {adminView ? "User" : user(token).userType} view
          </Button>
        )}
      </ProfileContainer>
    </>
  );
};

export default Profile;
