import React from "react";
import EditSharedArticle from "../SharedArticle/EditSharedArticle";

const EditAboutArticle = (props) => (
  <EditSharedArticle
    {...props}
    endpoint="about"
    customVar="aboutId"
    customId="about_id"
    customStatus="about_status"
    customStatusVar="aboutCategoryStatus"
    title={
      props.language === "ar" ? "تعديل مقالة عننا" : "Add about us article"
    }
  />
);

export default EditAboutArticle;
