import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "@emotion/styled";
import {
  FaLinkedin,
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaInstagramSquare,
} from "react-icons/fa";

const FooterWrapper = styled.div`
  background: #333333;
  color: #dddddd;
  padding: 0;
  margin: 0;
`;

const FooterContainer = styled(Container)`
  padding: 0;
  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const SocialLinks = styled.div`
  margin: 0;
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cccccc;
`;

const FooterDescription = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px 0;
`;

const FaceBook = styled(FaFacebookSquare)`
  font-size: 40px;
  margin: 0 10px;
  background-color: #ffffff;
  color: #29487c;
`;

const Youtube = styled(FaYoutubeSquare)`
  font-size: 40px;
  margin: 0 10px;
  background-color: #ffffff;
  color: #ff0100;
`;

const Instagram = styled(FaInstagramSquare)`
  font-size: 40px;
  margin: 0 10px;
  background-color: #ffffff;
  color: #dc4373;
`;

const Twitter = styled(FaTwitterSquare)`
  font-size: 40px;
  margin: 0 10px;
  background-color: #ffffff;
  color: #1ca1f2;
`;

const Linkedin = styled(FaLinkedin)`
  font-size: 40px;
  margin: 0 10px;
  background-color: #ffffff;
  color: #0177b5;
`;

const CustomLink = styled.p`
  text-align: center;
  margin: 0;
  padding-bottom: 15px;
`;

const Footer = ({ language }) => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <SocialLinks>
          <Link target="_blanket" to="/">
            <FaceBook />
          </Link>
          <Link target="_blanket" to="/">
            <Youtube />
          </Link>
          <Link target="_blanket" to="/">
            <Instagram />
          </Link>
          <Link target="_blanket" to="/">
            <Twitter />
          </Link>
          <Link target="_blanket" to="/">
            <Linkedin />
          </Link>
        </SocialLinks>
        <FooterDescription>
          {language === "ar"
            ? `جميع © الحقوق محفوظة لموقع ماسيو 2020`
            : `All © Copy Right Reserved to MASIO Website 2020`}
        </FooterDescription>
        <CustomLink>
          <a href="http://www.dwinatech.com" target="_blanket">
            {language === "ar"
              ? ` أنشأه محمد ضوينا`
              : `Created by Mohammed Dwina`}
          </a>
        </CustomLink>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
