import React from "react";
import SharedArticle from "../SharedArticle";

const WhatWeDo = (props) => (
  <SharedArticle
    {...props}
    endpoint="what-we-do"
    customId="what_we_do_id"
    customStatus="what_we_do_status"
    editLink="edit-what-we-do-article"
  />
);

export default WhatWeDo;
