import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import SpinnerComponent from "../Spinner";
import Notification from "../Notification";
import { baseApiUrl, user, setAxiosAuthHeader } from "../../helpers";
import firebase from "../../firebase-config";
import { Container, Form, Input, Button, Col } from "reactstrap";
import DashboardNav from "../Dashboard/DashboardNav";

const MembershipContainer = styled(Container)`
  padding-top: 10px;
  padding-bottom: 10px;
  ${({ language }) => language === "ar" && "text-align: right"}
`;

const UploadWrapper = styled(Col)`
  margin-top: 10px;
  > form > input {
    margin: 15px 0;
  }
`;

const UploadMembershipForm = ({ language, token }) => {
  const [file, setFile] = useState(null);
  const [errorConfig, setError] = useState({});
  const [membershipForm, setMembershipForm] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchForm = async () => {
    try {
      setAxiosAuthHeader(token);
      const response = await axios.get(
        `${baseApiUrl()}/upload-membership-form`
      );
      setMembershipForm(response.data[0]);
    } catch (error) {
      console.error("error ====", error);
    }
  };

  useEffect(() => {
    fetchForm();
  }, []);

  const deleteForm = async () => {
    try {
      await axios.delete(
        `${baseApiUrl()}/upload-membership-form/${
          membershipForm.upload_membership_form_id
        }`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFileAndForm = async () => {
    setAxiosAuthHeader(token);
    firebase
      .storage()
      .ref(`files/upload-form/${membershipForm.file_name}`)
      .delete()
      .then(() => {
        deleteForm();
      })
      .catch((error) => {
        if (error.message && error.message.includes("does not exist")) {
          deleteForm();
        }
        console.error("error ====", error);
      });
  };

  const handleFile = (e) => {
    e.preventDefault();
    setAxiosAuthHeader(token);

    setError({});
    if (
      (e.target.files[0] &&
        e.target.files[0] !== null &&
        e.target.files[0].type &&
        e.target.files[0].type.includes(".document")) ||
      e.target.files[0].type.includes("application/pdf")
    ) {
      setFile(e.target.files[0]);
    } else {
      setError({
        type: "danger",
        title: language === "ar" ? "خطاء" : "Error",
        message:
          language === "ar"
            ? "بجب عليك رفع ملف في سيغة pdf او word"
            : "You have to upload a file, its format is pdf or word",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      file === null ||
      !file.type ||
      (!file.type.includes(".document") &&
        !file.type.includes("application/pdf"))
    ) {
      setLoading(false);
      setError({
        type: "danger",
        title: language === "ar" ? "خطاء" : "Error",
        message:
          language === "ar"
            ? "بجب عليك رفع ملف في سيغة pdf او word"
            : "You have to upload a file, its format is pdf or word",
      });
      return;
    }
    if (membershipForm && membershipForm.file_name) {
      await deleteFileAndForm();
    }
    const fileName = `${uuidv4()}-${file.name}`;
    const uploadTask = firebase
      .storage()
      .ref(`files/upload-form/${fileName}`)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        // error function ....
        console.log(error);
        setLoading(false);
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("files/upload-form")
          .child(fileName)
          .getDownloadURL()
          .then(async (docUrl) => {
            await axios
              .post(`${baseApiUrl()}/upload-membership-form`, {
                docUrl,
                fileName,
                fullName: user(token).fullName,
              })
              .then((result) => {
                setMembershipForm(result.data && result.data[0]);
                setLoading(false);
                setError({
                  title:
                    language === "ar"
                      ? "تم الرفع بنجاح"
                      : "Uploaded successfully!",
                  message:
                    language === "ar"
                      ? "تم رفع ملفك بنجاح "
                      : "Your form uploaded successfully",
                  type: "success",
                });
                setTimeout(() => {
                  setError({});
                }, 8000);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  return (
    <>
      <DashboardNav token={token} language={language} />
      {loading && <SpinnerComponent />}
      <MembershipContainer language={language}>
        {errorConfig.title && (
          <Notification
            language={language}
            title={errorConfig.title}
            type={errorConfig.type}
            message={errorConfig.message}
          />
        )}
        <UploadWrapper onSubmit={handleSubmit}>
          <p>
            {language === "ar"
              ? "ارفع ملف العضوية "
              : "Upload membership form:"}
          </p>
          <Form method="post">
            <Input
              required
              type="file"
              onChange={handleFile}
              name="membershipForm"
            />
            <Button onClick={handleSubmit} color="primary" type="submit">
              {language === "ar" ? "ارسل" : "Submit"}
            </Button>
          </Form>
        </UploadWrapper>
      </MembershipContainer>
    </>
  );
};

export default UploadMembershipForm;
