import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Container } from "reactstrap";
import React, { Component } from "react";
import Notification from "../Notification";
import FormComponent from "../FormComponent";
import {
  user,
  baseApiUrl,
  validateForm,
  getQueryByName,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import { Redirect } from "react-router";
import DashboardNav from "../Dashboard/DashboardNav";
import styled from "@emotion/styled";

const MainTitle = styled.h3`
  ${({ language }) => language === "ar" && `text-align: right`}
`;

class EditArticle extends Component {
  previousOrder = null;

  state = {
    title: "",
    error: {},
    writer: "",
    status: "",
    order: null,
    image: null,
    subtitle: "",
    language: "",
    imageUrl: "",
    imageName: "",
    goHome: false,
    categories: [],
    description: "",
    articleId: null,
    isLoading: false,
  };

  componentDidMount() {
    const { pathname } = window.location;
    const pathnameParts = pathname && pathname.split("/");
    const articleId = pathnameParts && pathnameParts[pathnameParts.length - 1];
    const language = getQueryByName("lang");
    this.fetchArticle(articleId, language);
    this.fetchCategories(language);
  }

  fetchCategories = async (language) => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.get(
        `${baseApiUrl()}/categories?lang=${language}`
      );
      this.setState({ categories: response.data });
      return;
    } catch (error) {
      console.log("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  fetchArticle = async (articleId, language) => {
    this.setState({ isLoading: true });
    const { token } = this.props;
    try {
      const response = await axios.get(
        `${baseApiUrl()}/articles/${articleId}?lang=${language}`
      );
      if (
        (user(token).userType !== "admin" &&
          response.data &&
          response.data.writer !== user(token).fullName) ||
        !response.data
      ) {
        this.setState({ goHome: true });
        return;
      }
      this.setState({
        language,
        title: response.data.title,
        writer: response.data.writer,
        subtitle: response.data.subtitle,
        order: response.data.order_column,
        imageUrl: response.data.image_url,
        imageName: response.data.image_name,
        articleId: response.data.article_id,
        status: response.data.article_status,
        categoryId: response.data.category_id,
        description: response.data.description,
        isLoading: false,
      });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  updateArticle = async (data) => {
    return axios.put(`${baseApiUrl()}/articles`, data);
  };

  handleUpload = (data) => {
    const { image } = this.state;
    const updatedImageName = `${uuidv4()}-${image.name}`;

    const uploadTask = firebase
      .storage()
      .ref(`images/${updatedImageName}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
        this.setState({ isLoading: false });
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("images")
          .child(updatedImageName)
          .getDownloadURL()
          .then(async (url) => {
            await this.updateArticle({
              ...data,
              imageUrl: url,
              imageName: updatedImageName,
            })
              .then((response) => {
                if (response.data) {
                  this.setState({ goHome: true });
                }
              })
              .catch((error) => console.error(error));
          });
      }
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      image,
      title,
      order,
      writer,
      imageUrl,
      subtitle,
      language,
      articleId,
      imageName,
      categoryId,
      description,
    } = this.state;

    const callBack = (error) => {
      this.setState({ error });
    };
    if (
      !validateForm({
        image: image || imageName,
        title,
        order,
        validateOrder: true,
        imageUrl,
        callBack,
        categoryId,
        description,
        validateArticle: true,
      })
    ) {
      return;
    }

    const stateData = {
      title,
      order,
      writer,
      imageUrl,
      subtitle,
      language,
      articleId,
      imageName,
      categoryId,
      description,
      articleStatus: this.state.status,
    };
    this.setState({ isLoading: true });

    try {
      setAxiosAuthHeader(this.props.token);
      if (image) {
        if (imageName) {
          await firebase
            .storage()
            .ref(`images/${imageName}`)
            .delete()
            .catch((err) => {
              console.error(err);
              this.setState({ isLoading: false });
            });
          this.setState({ imageName: "", imageUrl: "" });
        }
        this.handleUpload(stateData);
      } else {
        await this.updateArticle(stateData)
          .then((response) => {
            if (response.data) {
              this.setState({ goHome: true });
            }
          })
          .catch((error) => console.error(error));
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  };

  updateOrder = async (orderId) => {
    this.setState({ isLoading: true });
    const { language } = this.state;
    const { token } = this.props;

    try {
      setAxiosAuthHeader(this.props.token);
      const response = await axios.get(
        `${baseApiUrl()}/articles/order/${orderId}?lang=${language}`
      );
      if (
        (user(token).userType !== "admin" &&
          response.data &&
          response.data.writer !== user(token).fullName) ||
        !response.data
      ) {
        this.setState({ goHome: true });
        return;
      }
      if (response.data) {
        await this.updateArticle({
          order: this.previousOrder,
          title: response.data.title,
          theme: response.data.theme,
          writer: response.data.writer,
          language: response.data.language,
          subtitle: response.data.subtitle,
          imageUrl: response.data.image_url,
          imageName: response.data.image_name,
          articleId: response.data.article_id,
          categoryId: response.data.category_id,
          description: response.data.description,
          articleStatus: response.data.article_status,
        });
      }
      await this.setState({
        order: orderId,
        error: {},
        isLoading: false,
      });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    if (this.state.order) {
      this.previousOrder = this.state.order;
    }
    if (e.target.name === "order" && e.target.value) {
      this.updateOrder(e.target.value);
    } else {
      this.setState({ [e.target.name]: e.target.value, error: {} });
    }
  };

  handleImage = (e) => {
    e.preventDefault();
    this.setState({ image: e.target.files[0], error: {} });
  };

  render() {
    const { error, goHome, isLoading } = this.state;
    const { language, token } = this.props;

    return (
      <>
        <DashboardNav token={token} language={language} />
        {goHome && <Redirect to="/" />}
        <Container className="my-3">
          {isLoading && <SpinnerComponent />}
          {error.title && (
            <Notification
              title={error.title}
              type={error.type}
              message={error.message}
            />
          )}
          <MainTitle language={language} className="display-5">
            {language === "ar" ? "تعديل المقالة" : "Add New Article"}
          </MainTitle>
          <FormComponent
            isEditForm
            showOrderInput
            token={token}
            language={language}
            onSubmit={this.onSubmit}
            title={this.state.title}
            image={this.state.image}
            theme={this.state.theme}
            order={this.state.order}
            writer={this.state.writer}
            status={this.state.status}
            imageUrl={this.state.imageUrl}
            handleImage={this.handleImage}
            subtitle={this.state.subtitle}
            handleChange={this.handleChange}
            categoryId={this.state.categoryId}
            categories={this.state.categories}
            description={this.state.description}
          />
        </Container>
      </>
    );
  }
}

export default EditArticle;
