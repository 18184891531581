import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import Notification from "../Notification";
import { user, baseApiUrl, setAxiosAuthHeader } from "../../helpers";
import firebase from "../../firebase-config";
import SharedArticle from "../SharedArticle";
import { FaRegFileWord } from "react-icons/fa";
import { Container, Form, Input, Button, Col } from "reactstrap";
import SpinnerComponent from "../Spinner";
import { FaLock } from "react-icons/fa";

const Lock = styled(FaLock)`
  display: block;
  font-size: 50px;
  margin-left: 10px;
`;

const MembershipContainer = styled(Container)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const DownloadLink = styled.a`
  display: inline-block;
`;

const FormLogo = styled(FaRegFileWord)`
  display: block;
  font-size: 50px;
  margin-left: 10px;
`;

const DownloadFormWrapper = styled(Col)``;

const UploadWrapper = styled(Col)`
  margin-top: 10px;
  > form > input {
    margin: 15px 0;
  }
`;

const Membership = (props) => {
  const { language, token } = props;
  const [file, setFile] = useState(null);
  const [errorConfig, setError] = useState({});
  const [membershipForm, setMembershipForm] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchForm = async () => {
    try {
      setAxiosAuthHeader(token);
      const response = await axios.get(
        `${baseApiUrl()}/upload-membership-form`
      );
      setMembershipForm(response.data[0]);
    } catch (error) {
      console.error("error ====", error);
    }
  };

  useEffect(() => {
    fetchForm();
  }, []);

  const handleFile = (e) => {
    e.preventDefault();
    setError({});
    if (
      (e.target.files[0] &&
        e.target.files[0].type &&
        e.target.files[0].type.includes(".document")) ||
      e.target.files[0].type.includes("application/pdf")
    ) {
      setFile(e.target.files[0]);
    } else {
      setError({
        type: "danger",
        title: language === "ar" ? "خطاء" : "Error",
        message:
          language === "ar"
            ? "بجب عليك رفع ملف في سيغة pdf او word"
            : "You have to upload a file, its format is pdf or word",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      file === null ||
      !file.type ||
      (!file.type.includes(".document") &&
        !file.type.includes("application/pdf"))
    ) {
      setLoading(false);
      setError({
        type: "danger",
        title: language === "ar" ? "خطاء" : "Error",
        message:
          language === "ar"
            ? "بجب عليك رفع ملف في سيغة pdf او word"
            : "You have to upload a file, its format is pdf or word",
      });
      return;
    }

    const fileName = `${uuidv4()}-${file.name}`;
    const uploadTask = firebase.storage().ref(`files/${fileName}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        // error function ....
        console.log(error);
        setLoading(false);
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("files")
          .child(fileName)
          .getDownloadURL()
          .then(async (docUrl) => {
            await axios
              .post(`${baseApiUrl()}/membership-form`, {
                docUrl,
                fileName,
                fullName: user(token).fullName,
                email: user(token).email,
              })
              .then(() => {
                setFile(null);
                setLoading(false);
                setError({
                  title:
                    language === "ar"
                      ? "تم الرفع بنجاح"
                      : "Uploaded successfully!",
                  message:
                    language === "ar"
                      ? "تم رفع ملفك بنجاح, سوف نتصل بك في اقرب وقت ممكن"
                      : "Your form uploaded successfully, we will be in touch as soon as possible.",
                  type: "success",
                });
                setTimeout(() => {
                  setError({});
                }, 8000);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  return (
    <>
      <SharedArticle
        {...props}
        endpoint="membership"
        customId="membership_id"
        customStatus="membership_status"
        editLink="edit-membership-article"
      >
        {loading && <SpinnerComponent />}
        <MembershipContainer>
          {errorConfig.title && (
            <Notification
              language={language}
              title={errorConfig.title}
              type={errorConfig.type}
              message={errorConfig.message}
            />
          )}
          {user(token).fullName ? (
            <>
              <DownloadFormWrapper>
                <h5>
                  {language === "ar"
                    ? "تنزيل ملف العضوية"
                    : "Download the membership form:"}
                </h5>
                <DownloadLink
                  target="_blank"
                  href={membershipForm && membershipForm.doc_url}
                >
                  <FormLogo />
                  {language === "ar" ? "تنزيل" : "Download"}
                </DownloadLink>
              </DownloadFormWrapper>
              <UploadWrapper md={{ size: 6 }} onSubmit={handleSubmit}>
                <p>
                  {language === "ar"
                    ? "ارفع ملف العضوية بعد ملاه"
                    : "Upload membership form after filling it:"}
                </p>
                <Form method="post">
                  <Input
                    required
                    type="file"
                    onChange={handleFile}
                    name="membershipForm"
                  />
                  <Button color="primary" type="submit">
                    {language === "ar" ? "ارسل" : "Submit"}
                  </Button>
                </Form>
              </UploadWrapper>
            </>
          ) : (
            <div>
              <p>
                {language === "ar"
                  ? "سجل دخول اولا ثم تسطتيع تحميل و رفع ملف اعضوية"
                  : "You need to sign in to download and upload membership form"}
              </p>
              <Lock />
            </div>
          )}
        </MembershipContainer>
      </SharedArticle>
    </>
  );
};

export default Membership;
