import axios from "axios";
import styled from "@emotion/styled";
import CustomModal from "../CustomModale";
import firebase from "../../firebase-config";
import { FaRegFileWord } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import DashboardNav from "../Dashboard/DashboardNav";
import { Container, Table, Button } from "reactstrap";
import { baseApiUrl, setAxiosAuthHeader } from "../../helpers";
import SpinnerComponent from "../Spinner";

const FormLogo = styled(FaRegFileWord)`
  display: block;
  font-size: 50px;
  margin-left: 10px;
`;

const DownloadLink = styled.a`
  display: inline-block;
`;

const Members = ({ language, token }) => {
  const [members, setMembers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMember, setCurrentMember] = useState({});

  const fetchMember = async () => {
    try {
      setIsLoading(true);
      setAxiosAuthHeader(token);
      const response = await axios.get(`${baseApiUrl()}/membership-form`);
      setIsLoading(false);
      setMembers(response.data || []);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMember();
  }, []);

  const toggle = (e, member) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setCurrentMember(member || {});
  };

  const removeForm = async (formId) => {
    try {
      setIsLoading(true);
      setAxiosAuthHeader(token);
      const response = await axios.delete(
        `${baseApiUrl()}/membership-form/${formId}`
      );
      if (response.data) {
        fetchMember();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    setIsOpen(false);
    firebase
      .storage()
      .ref(`files/${currentMember.file_name}`)
      .delete()
      .then(() => {
        removeForm(currentMember.membership_form_id);
      })
      .catch((error) => {
        if (error.message && error.message.includes("does not exist")) {
          removeForm(currentMember.membership_form_id);
        }
        console.error(error);
      });
  };

  return (
    <>
      <DashboardNav token={token} language={language} />
      {isLoading && <SpinnerComponent />}
      <Container>
        <Table>
          <thead>
            <tr>
              <th>{language === "ar" ? "الاسم الكامل" : "Full name"}</th>
              <th>{language === "ar" ? "البريد الاكتروني" : "Email"}</th>
              <th>{language === "ar" ? "الملف" : "Form"}</th>
              <th>{language === "ar" ? "تنزيل" : "Download"}</th>
              <th>{language === "ar" ? "امسح" : "Delete"}</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => {
              return (
                <tr>
                  <CustomModal
                    isOpen={isOpen}
                    toggle={toggle}
                    language={language}
                    className="text-danger"
                    actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
                    handleAction={handleDelete}
                    title={
                      language === "ar"
                        ? "هل انت متاكد تريد مسح هزا العضوء"
                        : "Are you sure want to delete this member form?"
                    }
                  />
                  <th>{member && member.full_name}</th>
                  <th>{member && member.email}</th>
                  <th>
                    <FormLogo />
                  </th>
                  <th>
                    <DownloadLink
                      target="_blank"
                      href={member && member.doc_url}
                    >
                      <th>{language === "ar" ? "تنزيل" : "Download"}</th>
                    </DownloadLink>
                  </th>
                  <th>
                    <Button color="danger" onClick={(e) => toggle(e, member)}>
                      <th>{language === "ar" ? "امسح" : "Delete"}</th>
                    </Button>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Members;
