import React from "react";
import SharedArticle from "../SharedArticle/AddSharedArticle";

const AddGetInvolvedArticle = (props) => (
  <SharedArticle
    {...props}
    endpoint="get-involved"
    title="Add get involved article"
    title={
      props.language === "ar"
        ? "اضافة مقالة جديدة عن مشاركتنا"
        : "Add get involved article"
    }
  />
);

export default AddGetInvolvedArticle;
