import React from "react";
import SharedArticle from "../SharedArticle/AddSharedArticle";

const AddMembershipArticle = (props) => (
  <SharedArticle
    {...props}
    endpoint="membership"
    title="Add membership article"
    title={
      props.language === "ar"
        ? "اضافة مقالة عن العضوية"
        : "Add membership article"
    }
  />
);

export default AddMembershipArticle;
