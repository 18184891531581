import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "@emotion/styled";
import axios from "axios";
import { Container, Button, CardText } from "reactstrap";
import {
  user,
  baseApiUrl,
  editorView,
  adminView,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import ContentsPlaceholder from "../ContentsPlaceholder";
import CustomModal from "../CustomModale";

const ArticleContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  ${({ language }) => language === "ar" && "text-align: right"}
`;

const ArticleContents = styled.div`
  position: relative;
`;

const CustomContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const StyledImag = styled.img`
  max-width: 100%;
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
`;

const ArticleDescription = styled.p`
  line-height: 35px !important;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    ${({ language }) => language === "ar" && "line-height: 20px;"}
  }
`;

const Article = ({ language, token }) => {
  const [article, setArticle] = useState({});
  const [goHome, setGoHome] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    if (language) {
      const { pathname } = window.location;
      const pathnameParts = pathname && pathname.split("/");
      const articleId =
        pathnameParts && pathnameParts[pathnameParts.length - 1];
      fetchData(articleId);
    }
  }, [language]);

  const fetchData = async (articleId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseApiUrl()}/articles/${articleId}?lang=${language}`
      );
      setArticle(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("error add cate", error);
      setIsLoading(false);
    }
  };

  const deleteArticle = () => {
    const { article_id } = article;
    axios
      .delete(`${baseApiUrl()}/articles/${article_id}?lang=${language}`)
      .then(() => {
        setGoHome(true);
      })
      .catch((error) => {
        console.error("error add cate", error);
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    const { image_name } = article;
    setIsLoading(true);
    setIsOpen(false);
    setAxiosAuthHeader(token);
    if (image_name) {
      firebase
        .storage()
        .ref(`images/${image_name}`)
        .delete()
        .then(() => {
          deleteArticle();
        })
        .catch((error) => {
          if (error.message && error.message.includes("does not exist")) {
            deleteArticle();
          } else {
            setIsLoading(false);
          }
        });
    } else {
      deleteArticle();
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ArticleContainer language={language}>
      {isLoading && <SpinnerComponent />}
      {goHome && <Redirect to="/" />}
      {!article.title ? (
        <ContentsPlaceholder language={language} />
      ) : (
        <CustomContainer>
          <ArticleContents>
            <StyledImag alt={article.image_name} src={article.image_url} />
            <Title>{article.title}</Title>
            {article.subtitle && <p className="lead">{article.subtitle}</p>}
            <ArticleDescription language={language} className="lead">
              {" "}
              {article.description}
            </ArticleDescription>
            {article.writer && (
              <CardText className="lead">
                {language === "ar" ? "الكاتب" : "Writer"}: {article.writer}
              </CardText>
            )}
          </ArticleContents>
          {adminView(token) ||
          (editorView(token) && article.writer === user(token).fullName) ? (
            <>
              <CustomModal
                isOpen={isOpen}
                toggle={toggle}
                language={language}
                className="text-danger"
                actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
                handleAction={handleDelete}
                title={
                  language === "ar"
                    ? "هل انت متاكد تريد مسح هزه المقالة"
                    : "Are you sure want to delete this article?"
                }
              />
              <Button onClick={toggle} color="danger">
                {language === "ar" ? "امسح" : "Delete"}
              </Button>{" "}
              <Link to={`/edit-article/${article.article_id}?lang=${language}`}>
                <Button color="primary">
                  {" "}
                  {language === "ar" ? "تعديل" : "Edit"}
                </Button>
              </Link>
            </>
          ) : null}
        </CustomContainer>
      )}
    </ArticleContainer>
  );
};

export default Article;
