import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavItem, NavLink, Collapse, Container } from "reactstrap";
import { RiHomeHeartLine } from "react-icons/ri";
import { withRouter } from "react-router";
import { user } from "../../helpers";
import { navLinks, authLinks } from "../../static-data/nav-links";
import styled from "@emotion/styled";
import Language from "../Language";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const CustomNavbar = styled(Navbar)`
  font-size: 22px;
  background-color: #000000;
  ${({ language }) =>
    language === "ar" && "font-family: Droid Arabic Kufi, serif !important;"}
`;

const NavbarToggler = (Component) => styled(Component)`
  color: #fff;
  font-size: 35px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const CustomNav = styled(Nav)`
  padding: 0;
`;

const CustomNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  color: #ffffff;
  &:hover,
  &:focus {
    color: #e51d1d !important;
  }
  ${({ active }) =>
    active &&
    `
    color: #e51d1d !important;
  `};

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 15px;
  }
  @media (min-width: 992px) {
    font-weight: 300;
    font-size: 20px;
  }
  @media (min-width: 768px) {
    border-bottom: none;
  }
`;

const NavComponent = ({ location, language, token, setLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [naveUrls, setNaveUrls] = useState([]);
  const [authLinksData, setAuthLinksData] = useState({});

  useEffect(() => {
    setNaveUrls(navLinks[language || "en"]);
    setAuthLinksData(authLinks[language || "en"]);
  });
  const toggle = () => setIsOpen(!isOpen);
  const menu = !isOpen ? FaBars : FaTimes;
  const ToggleMenus = NavbarToggler(menu);
  return (
    <div>
      <CustomNavbar expand="md" language={language}>
        <Container>
          <ToggleMenus onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <CustomNav
              className={language == "ar" ? "ml-auto" : "mr-auto"}
              navbar
            >
              {naveUrls &&
                naveUrls
                  .filter((navItem) => {
                    if (
                      navItem.link.includes("dashboard") &&
                      user(token).userType !== "admin" &&
                      user(token).userType !== "editor"
                    ) {
                      return false;
                    }
                    if (
                      navItem.link.includes("profile") &&
                      !user(token).fullName
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map((item) => {
                    if (item.name === "") {
                      return (
                        <NavItem key="/">
                          <CustomNavLink
                            tag={Link}
                            onClick={toggle}
                            language={language}
                            active={location.pathname === "/"}
                            to="/"
                          >
                            <RiHomeHeartLine style={{ fontSize: "25px" }} />
                          </CustomNavLink>
                        </NavItem>
                      );
                    }
                    return (
                      <NavItem key={item.name}>
                        <CustomNavLink
                          tag={Link}
                          onClick={toggle}
                          language={language}
                          active={location.pathname === item.link}
                          to={item.link}
                        >
                          {item.name}
                        </CustomNavLink>
                      </NavItem>
                    );
                  })}
            </CustomNav>
            <CustomNavLink
              tag={Link}
              onClick={toggle}
              language={language}
              active={location.pathname === "/login"}
              to={`/${user(token).userId ? "logout" : "login"}`}
            >
              {user(token).userId ? authLinksData.logout : authLinksData.login}
            </CustomNavLink>
          </Collapse>
          <Language
            isOpen={isOpen}
            language={language}
            setLanguage={setLanguage}
          />
        </Container>
      </CustomNavbar>
    </div>
  );
};

export default withRouter(NavComponent);
