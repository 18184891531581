export const navLinks = {
  en: [
    { name: "", link: "/" },
    { name: "About us", link: "/about" },
    { name: "What we do", link: "/what-we-do" },
    { name: "Get involved", link: "/get-involved" },
    { name: "Membership", link: "/membership" },
    { name: "Contact us", link: "/contact" },
    { name: "Dashboard", link: "/dashboard" },
    { name: "Profile", link: "/profile" },
  ],
  ar: [
    { name: "", link: "/" },
    { name: "عننا", link: "/about" },
    { name: "مازا نفعل", link: "/what-we-do" },
    { name: "شاركننا", link: "/get-involved" },
    { name: "العضوية", link: "/membership" },
    { name: "اتصل بنا", link: "/contact" },
    { name: "لوحة التحكم", link: "/dashboard" },
    { name: "الملف الشخصي", link: "/profile" },
  ],
};

export const dashboardNavLinks = {
  en: [
    { name: "Add Category", link: "/add-category" },
    { name: "Add Article", link: "/add-article" },
    { name: "Add Hero", link: "/add-hero" },
    { name: "Member", link: "/members" },
    { name: "Users", link: "/users" },
    { name: "Upload membership form", link: "/upload-membership-form" },
    {
      name: "Show More",
      link: [
        { name: "Add about us article", link: "/add-about-us-article" },
        { name: "Add what we do article", link: "/add-what-we-do-article" },
        { name: "Add get involved article", link: "/add-get-involved-article" },
        { name: "Add membership article", link: "/add-membership-article" },
      ],
    },
  ],
  ar: [
    { name: "إضافة فئة", link: "/add-category" },
    { name: "إضافة مقالة", link: "/add-article" },
    { name: "اضافة هيرو", link: "/add-hero" },
    { name: "الاعضاء", link: "/members" },
    { name: "المستخدمين", link: "/users" },
    { name: "ارفع ملف العضوية", link: "/upload-membership-form" },
    {
      name: "اضافة المزيد",
      link: [
        { name: "إضافة مقالة عننا", link: "/add-about-us-article" },
        { name: "إضافة مقالة عن مازا نفعل", link: "/add-what-we-do-article" },
        {
          name: "إضافة مقالة عن المشاركة معنا",
          link: "/add-get-involved-article",
        },
        { name: "إضافة مقالة عن العضوية", link: "/add-membership-article" },
      ],
    },
  ],
};

export const authLinks = {
  en: {
    login: "Login",
    logout: "Logout",
  },
  ar: {
    login: "تسجيل الدخول",
    logout: "تسجيل خروج",
  },
};
