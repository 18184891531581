import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Container, Row, Col, Button } from "reactstrap";
import axios from "axios";
import ArticleCard from "../Article/ArticleCard";
import {
  user,
  editorView,
  baseApiUrl,
  sortCallBack,
  adminView,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import CustomModal from "../CustomModale";

const CategoryContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px 5px 10px;
  ${({ backgroundImage, language }) => `
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: 52% 75%;
    ${language === "ar" && "text-align: right"}
    `};
  @media (min-width: 768px) {
    padding: 0 0 15px 0;
  }
`;

const CategoryContents = styled(Container)`
  position: relative;
  z-index: 1;
  padding-right: 0;
  padding-left: 0;
  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
`;

const ArticleRow = styled(Row)`
  margin: 0;
`;

const Overflow = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0, 0, 0, 0.5);
`;

const CategoryText = styled.div`
  max-height: 100%;
  width: 100%;
  color: #000000;
  ${({ theme }) => theme === "dark" && `color: #dddddd`}
`;

const CategoryDescription = styled.p`
  line-height: 35px;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    ${({ language }) => language === "ar" && "line-height: 20px;"}
  }
`;

const CustomCol = styled(Col)`
  ${({ layout, index, language }) =>
    `
    ${
      language === "en" &&
      `
    ${
      layout === "portrait" &&
      `
      ${(index + 1) % 2 === 0 ? "padding-right: 0" : "padding-right: 10px"};
      padding-left: 0;
      @media (min-width: 576px) {
        ${(index + 1) % 3 === 0 ? "padding-right: 0" : "padding-right: 10px"};
        padding-left: 0;
      }
    `
    };

    ${
      layout === "landscape" &&
      `
      padding-right: 0;
      padding-left: 0;
      @media (min-width: 576px) {
        ${(index + 1) % 2 === 0 ? "padding-right: 0" : "padding-right: 10px"};
        padding-left: 0;
      }
    `
    };
    @media (min-width: 992px) {
      ${(index + 1) % 3 === 0 ? "padding-right: 0" : "padding-right: 10px"};
      padding-left: 0;
    }
    `
    };

    ${
      language === "ar" &&
      `
    ${
      layout === "portrait" &&
      `
      ${(index + 1) % 2 === 0 ? "padding-left: 0" : "padding-left: 10px"};
      padding-right: 0;
      @media (min-width: 576px) {
        ${(index + 1) % 3 === 0 ? "padding-left: 0" : "padding-left: 10px"};
        padding-right: 0;
      }
    `
    };

    ${
      layout === "landscape" &&
      `
      padding-right: 0;
      padding-left: 0;
      @media (min-width: 576px) {
        ${(index + 1) % 2 === 0 ? "padding-left: 0" : "padding-left: 10px"};
        padding-right: 0;
      }
    `
    };
 
    @media (min-width: 992px) {
      ${(index + 1) % 3 === 0 ? "padding-left: 0" : "padding-left: 10px"};
      padding-right: 0;
    }
    `
    }

`}
`;

const Category = ({ token, language, category, fetchCategories }) => {
  const [articles, setArticles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    if (language) {
      fetchData();
    }
  }, [language]);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${baseApiUrl()}/articles/category/${
          category.category_id
        }?lang=${language}`
      );
      setArticles(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("error add cate", error);
      setIsLoading(false);
    }
  };

  const removeCategory = (category) => {
    axios
      .delete(
        `${baseApiUrl()}/categories/${category.category_id}?lang=${language}`
      )
      .then(() => {
        fetchCategories();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("error add cate", error);
      });
  };

  const deleteCategory = async (category) => {
    if (category.image_name) {
      firebase
        .storage()
        .ref(`images/${category.image_name}`)
        .delete()
        .then(() => {
          removeCategory(category);
        })
        .catch((error) => {
          if (error.message && error.message.includes("does not exist")) {
            removeCategory(category);
          } else {
            setIsLoading(false);
          }
        });
    } else {
      removeCategory(category);
    }
  };

  const deleteArticle = (isDeleteCategory, article) => {
    axios
      .delete(`${baseApiUrl()}/articles/${article.article_id}?lang=${language}`)
      .then(() => {
        if (isDeleteCategory) {
          deleteCategory(category);
        }
      })
      .catch((error) => {
        console.error("error add cate", error);
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    setIsOpen(false);
    setIsLoading(true);
    setAxiosAuthHeader(token);
    if (articles.length) {
      articles.forEach((article, index) => {
        const isDeleteCategory = articles.length - 1 === index;
        if (article.image_name) {
          firebase
            .storage()
            .ref(`images/${article.image_name}`)
            .delete()
            .then(() => {
              deleteArticle(isDeleteCategory, article);
            })
            .catch((error) => {
              if (error.message && error.message.includes("does not exist")) {
                deleteArticle(isDeleteCategory, article);
              } else {
                setIsLoading(false);
              }
            });
        } else {
          deleteArticle(isDeleteCategory, article);
        }
      });
    } else {
      deleteCategory(category);
    }
  };

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <CategoryContainer backgroundImage={category.image_url} language={language}>
      {isLoading && <SpinnerComponent />}
      <CategoryContents>
        <CategoryText theme={category.theme}>
          <Title>{category.title}</Title>
          {category.subtitle && <p className="lead">{category.subtitle}</p>}
          <CategoryDescription language={language} className="lead">
            {" "}
            {category.description}
          </CategoryDescription>
          {adminView(token) ||
          (editorView(token) && category.writer === user(token).fullName) ? (
            <>
              <CustomModal
                isOpen={isOpen}
                toggle={toggle}
                language={language}
                className="text-danger"
                actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
                handleAction={handleDelete}
                title={
                  language === "ar"
                    ? "هل انت متاكد تريد مسح هزه الفئة, سوف تمسح كل المقالات التي لها صلة بهزه الفئة"
                    : "Are you sure want to delete this category? it will delete all articles related to it"
                }
              />
              <Button onClick={toggle} color="danger">
                {language === "ar" ? "امسح" : "Delete"}
              </Button>{" "}
              <Link
                to={`/edit-category/${category.category_id}?lang=${language}`}
              >
                <Button color="primary">
                  {language === "ar" ? "تعديل" : "Edit"}
                </Button>
              </Link>
            </>
          ) : null}
        </CategoryText>
        <ArticleRow>
          {articles
            .filter(
              (art) =>
                adminView(token) ||
                editorView(token) ||
                art.article_status === "approved"
            )
            .sort(sortCallBack)
            .map((article, index) => (
              <CustomCol
                lg={4}
                index={index}
                className="my-2"
                language={language}
                layout={category.layout}
                key={article.article_id}
                sm={category.layout === "portrait" ? 4 : 6}
                xs={category.layout === "portrait" ? 6 : 12}
              >
                <ArticleCard
                  token={token}
                  language={language}
                  title={article.title}
                  layout={category.layout}
                  writer={article.writer}
                  subtitle={article.subtitle}
                  articleId={article.article_id}
                  description={article.description}
                  imageSrc={article.image_url}
                />
              </CustomCol>
            ))}
        </ArticleRow>
      </CategoryContents>
      {category.theme === "dark" && <Overflow />}
    </CategoryContainer>
  );
};

export default Category;
