import React from "react";
import SharedArticle from "../SharedArticle";

const About = (props) => (
  <SharedArticle
    {...props}
    endpoint="about"
    customId="about_id"
    editLink="edit-about"
    customStatus="about_status"
  />
);

export default About;
