import React from "react";
import { Redirect } from "react-router-dom";

const Logout = ({ setToken }) => {
  setToken("");
  localStorage.setItem("adminView", JSON.stringify(false));
  return <Redirect to="/" />;
};

export default Logout;
