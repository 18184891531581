import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Collapse,
  Container,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { user } from "../../helpers";
import { dashboardNavLinks } from "../../static-data/nav-links";

const NavWrapper = styled.div`
  border-top: 1px solid #cccccc;
`;

const CustomNavLink = styled(NavLink)`
  ${({ active }) =>
    active &&
    `
    color: #e51d1d !important;
  `};
`;

const DashboardNav = ({ location, language, token }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [naveUrls, setNaveUrls] = useState([]);

  useEffect(() => {
    setNaveUrls(dashboardNavLinks[language || "en"]);
  });

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavWrapper>
      <Navbar color="dark" dark expand="md">
        <Container>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {naveUrls &&
                naveUrls
                  .filter((navItem) => {
                    if (
                      (navItem.link.includes("users") ||
                        navItem.link.includes("members")) &&
                      user(token).userType !== "admin"
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map((item) => {
                    if (typeof item.link === "object") {
                      return (
                        <UncontrolledDropdown nav inNavbar key={item.name}>
                          <DropdownToggle nav caret>
                            {item.name}
                          </DropdownToggle>
                          <DropdownMenu right>
                            {item.link.map((property, index) => (
                              <div key={property.name}>
                                <DropdownItem
                                  tag={Link}
                                  active={location.pathname === property.link}
                                  to={property.link}
                                >
                                  {property.name}
                                </DropdownItem>
                                {index !== item.link.length - 1 && (
                                  <DropdownItem divider />
                                )}
                              </div>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      );
                    }
                    return (
                      <NavItem key={item.name}>
                        <CustomNavLink
                          tag={Link}
                          onClick={toggle}
                          language={language}
                          active={location.pathname === item.link}
                          to={item.link}
                        >
                          {item.name}
                        </CustomNavLink>
                      </NavItem>
                    );
                  })}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </NavWrapper>
  );
};

export default withRouter(DashboardNav);
