import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
} from "reactstrap";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const placeholder = `${process.env.PUBLIC_URL}/placeholder.jpg`;

const StyledContainer = styled(Container)`
  width: 100%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 992px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const Overflow = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0, 0, 0, 0.2);
`;

const StyledCarouselCaption = styled(CarouselCaption)`
  display: block !important;
  > h3,
  > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
  }
  > h3 {
    font-size: 25px;
    line-height: 30px;
  }
  color: #000000;
  ${({ theme }) => theme === "dark" && "color: #ffffff"};
  @media (min-width: 992px) {
    > p {
      line-height: 30px;
      font-size: 20px;
      font-weight: 400;
    }
    > h3 {
      font-size: 35px;
      line-height: 30px;
    }
  }
`;

const CarouselItemImage = styled.div`
  height: 400px;
  display: flex;
  color: #ffffff;
  position: relative;
  align-items: center;
  ${({ backgroundImage }) => `
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: 52% 75%;
  `};
`;

const HeroCarousel = ({ items, language }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(0);

  const handleSetActiveIndex = (activeIndex) => {
    setActiveIndex(activeIndex);
  };

  const handleSetAnimating = (animating) => {
    setAnimating(animating);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    handleSetActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    handleSetActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    handleSetActiveIndex(newIndex);
  };

  const slides =
    items &&
    items.map((item) => {
      const source = item.image_url ? item.image_url : placeholder;
      return (
        <CarouselItem
          onExiting={() => handleSetAnimating(true)}
          onExited={() => handleSetAnimating(false)}
          key={item.hero_id}
        >
          <CarouselItemImage backgroundImage={source} className="main-hero" />
          <Link to={`/hero-view/${item.hero_id}?lang=${language}`}>
            <StyledCarouselCaption
              captionText={item.description}
              captionHeader={item.title}
              theme={item.theme}
            />
          </Link>
          {item.theme === "dark" && <Overflow />}
        </CarouselItem>
      );
    });

  if (!items || !items.length) {
    return null;
  }
  return (
    <StyledContainer>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        {items.length > 1 && (
          <>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </>
        )}
      </Carousel>
    </StyledContainer>
  );
};

export default HeroCarousel;
