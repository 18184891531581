import axios from "axios";
import { Container } from "reactstrap";
import React, { Component } from "react";
import FormComponent from "../FormComponent";
import Notification from "../Notification";
import {
  user,
  baseApiUrl,
  validateForm,
  getQueryByName,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import DashboardNav from "../Dashboard/DashboardNav";
import { Redirect } from "react-router";
import styled from "@emotion/styled";

const MainTitle = styled.h3`
  ${({ language }) => language === "ar" && `text-align: right`}
`;

class EditSharedArticle extends Component {
  previousOrder = null;

  state = {
    title: "",
    theme: "",
    error: {},
    status: "",
    writer: "",
    order: null,
    image: null,
    subtitle: "",
    language: "",
    imageUrl: "",
    imageName: "",
    goToThisPath: "",
    description: "",
    articleId: null,
    isLoading: false,
  };

  componentDidMount() {
    const { pathname } = window.location;
    const pathnameParts = pathname && pathname.split("/");
    const articleId = pathnameParts && pathnameParts[pathnameParts.length - 1];
    this.fetchData(articleId, getQueryByName("lang"));
  }

  fetchData = async (articleId, language) => {
    const { token } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.get(
        `${baseApiUrl()}/${this.props.endpoint}/${articleId}?lang=${language}`
      );
      if (
        (user(token).userType !== "admin" &&
          response.data &&
          response.data.writer !== user(token).fullName) ||
        !response.data
      ) {
        this.setState({ goToThisPath: "/" });
        return;
      }
      this.setState({
        language,
        title: response.data.title,
        theme: response.data.theme,
        writer: response.data.writer,
        subtitle: response.data.subtitle,
        imageUrl: response.data.image_url,
        order: response.data.order_column,
        imageName: response.data.image_name,
        status: response.data[this.props.customStatus],
        articleId: response.data[this.props.customId],
        description: response.data.description,
        isLoading: false,
      });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  updateSharedArticle = async (data) => {
    const { endpoint } = this.props;
    return axios.put(`${baseApiUrl()}/${endpoint}`, data);
  };

  handleUpload = (data) => {
    const { image } = this.state;
    const updatedImageName = `${Date.now().toString()}-${image.name}`;

    const uploadTask = firebase
      .storage()
      .ref(`images/${updatedImageName}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
        this.setState({ isLoading: false });
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("images")
          .child(updatedImageName)
          .getDownloadURL()
          .then(async (url) => {
            await this.updateSharedArticle({
              ...data,
              imageUrl: url,
              imageName: updatedImageName,
            }).then((response) => {
              const { endpoint } = this.props;

              if (response.data) {
                this.setState({ goToThisPath: `/${endpoint}` });
              }
            });
          });
      }
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      image,
      title,
      theme,
      order,
      writer,
      imageUrl,
      subtitle,
      language,
      imageName,
      description,
    } = this.state;

    const callBack = (error) => {
      this.setState({ error });
    };
    if (
      !validateForm({
        image: true,
        title,
        order,
        callBack,
        description,
        validateOrder: true,
      })
    ) {
      return;
    }

    const stateData = {
      title,
      theme,
      order,
      writer,
      imageUrl,
      subtitle,
      language,
      imageName,
      [this.props.customVar]: this.state.articleId,
      [this.props.customStatusVar]: this.state.status,
      description,
    };
    try {
      setAxiosAuthHeader(this.props.token);
      this.setState({ isLoading: true });
      if (image) {
        if (imageName) {
          await firebase
            .storage()
            .ref(`images/${imageName}`)
            .delete()
            .then(() => {
              this.setState({ imageName: "", imageUrl: "" });
            })
            .catch((err) => {
              console.error(err);
              if (err.message && err.message.includes("does not exist")) {
                this.setState({ isLoading: false });
              }
            });
        }
        this.handleUpload(stateData);
      } else {
        const { endpoint } = this.props;
        await this.updateSharedArticle(stateData).then((response) => {
          if (response.data) {
            this.setState({ goToThisPath: `/${endpoint}` });
          }
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  };

  updateOrder = async (orderId) => {
    this.setState({ isLoading: true });
    const { language } = this.state;
    try {
      setAxiosAuthHeader(this.props.token);
      const response = await axios.get(
        `${baseApiUrl()}/${
          this.props.endpoint
        }/order/${orderId}?lang=${language}`
      );

      if (response.data) {
        await this.updateSharedArticle({
          title: response.data.title,
          theme: response.data.theme,
          writer: response.data.writer,
          subtitle: response.data.subtitle,
          language: response.data.language,
          imageUrl: response.data.image_url,
          imageName: response.data.image_name,
          [this.props.customVar]: response.data[this.props.customId],
          description: response.data.description,
          order: this.previousOrder,
        });
      }
      await this.setState({
        order: orderId,
        error: {},
        isLoading: false,
      });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    if (this.state.order) {
      this.previousOrder = this.state.order;
    }
    if (e.target.name === "order" && e.target.value) {
      this.updateOrder(e.target.value);
    } else {
      this.setState({ [e.target.name]: e.target.value, error: {} });
    }
  };

  handleImage = (e) => {
    e.preventDefault();
    this.setState({ image: e.target.files[0], error: {} });
  };

  handleRemoveImage = async (e) => {
    e.preventDefault();
    const { imageName } = this.state;
    if (imageName) {
      await firebase
        .storage()
        .ref(`images/${imageName}`)
        .delete()
        .then(() => {
          this.setState({ imageUrl: "", imageName: "", image: null });
        })
        .catch((error) => {
          if (error.message && error.message.includes("does not exist")) {
            this.setState({ imageUrl: "", imageName: "", image: null });
          }
          console.error(error);
        });
    }
  };

  render() {
    const { error, goToThisPath, isLoading } = this.state;
    const { language, token } = this.props;
    return (
      <>
        <DashboardNav token={token} language={language} />
        {goToThisPath && <Redirect to={goToThisPath} />}
        <Container className="my-3">
          {isLoading && <SpinnerComponent />}
          {error.title && (
            <Notification
              title={error.title}
              type={error.type}
              message={error.message}
            />
          )}
          <MainTitle language={language} className="display-5">
            {this.props.title}
          </MainTitle>
          <FormComponent
            isEditForm
            showOrderInput
            token={token}
            language={language}
            onSubmit={this.onSubmit}
            title={this.state.title}
            image={this.state.image}
            theme={this.state.theme}
            order={this.state.order}
            status={this.state.status}
            writer={this.state.writer}
            imageUrl={this.state.imageUrl}
            handleImage={this.handleImage}
            subtitle={this.state.subtitle}
            handleChange={this.handleChange}
            description={this.state.description}
            handleRemoveImage={this.handleRemoveImage}
          />
        </Container>
      </>
    );
  }
}

export default EditSharedArticle;
