import React from "react";
import EditSharedArticle from "../SharedArticle/EditSharedArticle";

const EditMembership = (props) => (
  <EditSharedArticle
    {...props}
    endpoint="membership"
    customVar="membershipId"
    customId="membership_id"
    customStatus="membership_status"
    customStatusVar="membershipStatus"
    title="Edit membership article"
    title={
      props.language === "ar"
        ? "تعديل مقالة عن العضوية"
        : "Edit membership article"
    }
  />
);

export default EditMembership;
