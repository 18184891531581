import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "@emotion/styled";

const CustomHeading = styled(ModalHeader)`
  > button {
    ${({ language }) => language === "ar" && "margin-left: 0 !important"}
  }
`;

const CustomFooter = styled(ModalFooter)`
  ${({ language }) => language === "ar" && "justify-content: flex-start"}
`;

const CustomModal = (props) => {
  const {
    title,
    isOpen,
    toggle,
    language,
    className,
    description,
    handleAction,
    actionButtonTitle,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className}>
      {title && (
        <CustomHeading language={language} toggle={toggle}>
          {title}
        </CustomHeading>
      )}
      {description && <ModalBody>{description}</ModalBody>}
      <CustomFooter language={language}>
        <Button color="danger" onClick={handleAction}>
          {actionButtonTitle}
        </Button>{" "}
        <Button color="success" onClick={toggle}>
          {language === "ar" ? "الغاء" : "Cancel"}
        </Button>
      </CustomFooter>
    </Modal>
  );
};

export default CustomModal;
