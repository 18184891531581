import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
  Container,
  FormFeedback,
} from "reactstrap";
import styled from "@emotion/styled";
import { baseApiUrl } from "../../helpers";
import axios from "axios";
import Notification from "../Notification";

const ContactContainer = styled.div`
  padding: 50px 0;
  ${({ language }) => language === "ar" && "text-align: right"}
`;

const ContactTitle = styled.h1`
  margin: 10px 0;
  padding: 0;
  font-size: 30px;
  text-align: center;
`;

const Registration = ({ language }) => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const validateCredential = () => {
    let isValid;
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email && !password) {
      setError({
        ...error,
        email:
          language === "ar" ? "البريد الالكتروني مطلوب!" : "Email is required!",
        password:
          language === "ar" ? "كلمة المرور مطلوبة!" : "Password is required!",
      });
      isValid = false;
    } else if (!email || !validEmail.test(email)) {
      setError({
        ...error,
        email: !email
          ? language === "ar"
            ? "البريد الالكتروني مطلوب!"
            : "Email is required!"
          : language === "ar"
          ? "يجب عليك كتابة بريد إلكتروني صالح!"
          : "You have to write a valid email!",
      });
      isValid = false;
    } else if (!fullName || fullName.length < 3) {
      setError({
        ...error,
        fullName: !fullName
          ? language === "ar"
            ? "الاسم الكامل مطلوب!"
            : "Full name is required!"
          : language === "ar"
          ? "يجب أن يكون طول الاسم الكامل أطول من ٣ أحرف"
          : "Full name length has to be longer than 3 characters",
      });
      isValid = false;
    } else if (!password || password.length < 5) {
      setError({
        ...error,
        password: !password
          ? language === "ar"
            ? "كلمة المرور مطلوبة!"
            : "Password is required!"
          : language === "ar"
          ? "يجب أن يكون طول كلمة المرور أطول من 5 أحرف"
          : "Password length has to be longer than 5 characters",
      });
      isValid = false;
    } else if (password !== confirmPassword) {
      setError({
        ...error,
        confirmPassword: !confirmPassword
          ? language === "ar"
            ? "تاكيد كلمة المرور مطلوب"
            : "Confirm Password is required!"
          : language === "ar"
          ? "يجب تاكيد كلمة المرور مطابقة كلمة المرور"
          : "Confirm Password has to match password",
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateCredential()) {
      return;
    }
    try {
      if (password.length < 5) {
        return;
      }
      if (password !== confirmPassword) {
        return;
      }
      const response = await axios.post(`${baseApiUrl()}/users`, {
        email,
        language,
        fullName,
        password,
      });
      if (response.data) {
        setMessage(response.data.message[language]);
      }
    } catch (error) {
      setError({
        title: language === "ar" ? "خطاء" : "Error",
        type: "danger",
        message:
          error.response &&
          error.response.data &&
          error.response.data.message[language],
      });
      console.error("error ", error);
    }
    return;
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setError({});
    setEmail(e.target.value);
  };

  const handleFullNameChange = (e) => {
    e.preventDefault();
    setError({});
    setFullName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setError({});
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    setError({});
    setConfirmPassword(e.target.value);
  };

  if (message) {
    return (
      <ContactContainer language={language}>
        <Container>
          <h3>{message}</h3>
        </Container>
      </ContactContainer>
    );
  }
  return (
    <ContactContainer language={language}>
      <Container>
        <Row xs="1" sm="1">
          <Col
            sm={{ size: 6, offset: 3 }}
            style={language === "ar" ? { "margin-right": "25%" } : {}}
          >
            {error.title && (
              <Notification
                title={error.title}
                type={error.type}
                message={error.message}
              />
            )}
            <ContactTitle>
              {language === "ar" ? "التسجيل" : "Sign up"}
            </ContactTitle>
            <Form onSubmit={handleSubmit} method="post" action="/users">
              <FormGroup>
                <Label for="Email">
                  {language === "ar" ? "البريد الإلكتروني" : "Email"}
                </Label>
                <Input
                  required
                  id="Email"
                  type="email"
                  name="email"
                  onChange={handleEmailChange}
                  invalid={Boolean(error.email)}
                  placeholder={
                    language === "ar" ? "البريد الإلكتروني" : "Email"
                  }
                />
                <FormFeedback>{error.email}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="FullName">
                  {" "}
                  {language === "ar" ? "الاسم الكامل" : "Full name"}
                </Label>
                <Input
                  required
                  type="text"
                  id="FullName"
                  name="fullName"
                  onChange={handleFullNameChange}
                  invalid={Boolean(error.fullName)}
                  placeholder={language === "ar" ? "الاسم الكامل" : "Full name"}
                />
                <FormFeedback>{error.fullName}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="Password">
                  {language === "ar" ? "كلمه السر" : "Password"}
                </Label>
                <Input
                  required
                  id="Password"
                  type="password"
                  name="password"
                  onChange={handlePasswordChange}
                  invalid={Boolean(error.password)}
                  placeholder={language === "ar" ? "كلمه السر" : "Password"}
                />
                <FormFeedback>{error.password}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="ConfirmPassword">
                  {language === "ar" ? "تاكيد كلمه السر" : "Confirm password"}
                </Label>
                <Input
                  required
                  type="password"
                  id="ConfirmPassword"
                  name="confirmPassword"
                  onChange={handleConfirmPasswordChange}
                  invalid={Boolean(error.confirmPassword)}
                  placeholder={
                    language === "ar" ? "تاكيد كلمه السر" : "Confirm password"
                  }
                />
                <FormFeedback>{error.confirmPassword}</FormFeedback>
              </FormGroup>
              <Button type="submit" block color="success">
                {language === "ar" ? "سجل" : "Sign up"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </ContactContainer>
  );
};

export default Registration;
