import React from "react";
import SharedArticle from "../SharedArticle";

const GetInvolvedArticle = (props) => (
  <SharedArticle
    {...props}
    endpoint="get-involved"
    customId="get_involved_id"
    customStatus="get_involved_status"
    editLink="edit-get-involved-article"
  />
);

export default GetInvolvedArticle;
