import React from "react";
import EditSharedArticle from "../SharedArticle/EditSharedArticle";

const EditGetInvolvedArticle = (props) => (
  <EditSharedArticle
    {...props}
    endpoint="get-involved"
    customVar="getInvolvedId"
    customId="get_involved_id"
    customStatus="get_involved_status"
    customStatusVar="getInvolvedStatus"
    title="Edit get involved article"
    title={
      props.language === "ar"
        ? "تعديل مقالة عن مشاركتنا"
        : "Edit get involved article"
    }
  />
);

export default EditGetInvolvedArticle;
