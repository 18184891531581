import React from "react";
import SharedArticle from "../SharedArticle/AddSharedArticle";

const AddWhatWeDoArticle = (props) => (
  <SharedArticle
    {...props}
    endpoint="what-we-do"
    title="Add what we do article"
    title={
      props.language === "ar"
        ? "اضافة مقالة عن مازا نفعل"
        : "Add what we do article"
    }
  />
);

export default AddWhatWeDoArticle;
