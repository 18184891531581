import axios from "axios";
import SpinnerComponent from "../Spinner";
import CustomModal from "../CustomModale";
import { baseApiUrl, setAxiosAuthHeader } from "../../helpers";
import React, { useState, useEffect } from "react";
import DashboardNav from "../Dashboard/DashboardNav";
import { Container, Table, Button, FormGroup, Input } from "reactstrap";

const Users = ({ language, token }) => {
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState("");
  const [userType, setUserType] = useState("");

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      setAxiosAuthHeader(token);
      const response = await axios.get(`${baseApiUrl()}/users?`);
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEdit = async (user) => {
    try {
      setAxiosAuthHeader(token);
      const response = await axios.put(`${baseApiUrl()}/users?`, {
        ...user,
        user_type: userType,
      });
      if (response.data) {
        setEditMode("");
        setUserType("");
        fetchUsers();
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  const handleUserTypeChange = (e) => {
    e.preventDefault();
    setUserType(e.target.value);
  };

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleDelete = async (user) => {
    setIsOpen(!isOpen);
    setIsLoading(true);
    try {
      setAxiosAuthHeader(token);
      const response = await axios.delete(
        `${baseApiUrl()}/users/${user.user_id}`
      );
      if (response.data) {
        fetchUsers();
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  const renderUser = (user) => {
    if (user.user_id === editMode) {
      return (
        <tr key={user.user_id}>
          <CustomModal
            isOpen={isOpen}
            toggle={toggle}
            language={language}
            className="text-danger"
            actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
            handleAction={() => handleDelete(user)}
            title={
              "ar"
                ? "هل انت متاكد تريد مسح هزا المستخدم"
                : "Are you sure want to delete this user?"
            }
          />
          <th>{user.full_name}</th>
          <th>{user.email}</th>
          <th>
            <FormGroup>
              <Input
                name="userType"
                type="select"
                value={userType}
                id="selectUserType"
                selected="selected"
                onChange={handleUserTypeChange}
              >
                <option>
                  {language === "ar"
                    ? "اختار نوع المستخدم"
                    : "Select user type"}
                </option>
                <option value="editor">
                  {language === "ar" ? "محرر" : "Editor"}
                </option>
                <option value="admin">
                  {language === "ar" ? "مدير" : "Admin"}
                </option>
                <option value="member">
                  {language === "ar" ? "عضو" : "Member"}
                </option>
                <option value="user">
                  {language === "ar" ? "مستخدم" : "User"}
                </option>
              </Input>
            </FormGroup>
          </th>
          <th>
            <Button block color="primary" onClick={() => handleEdit(user)}>
              {language === "ar" ? "ارسل" : "send"}
            </Button>{" "}
            <Button
              onClick={() => {
                setEditMode("");
              }}
              block
              color="danger"
            >
              {language === "ar" ? "الغاء" : "Cancel"}
            </Button>
          </th>
          <th>
            <Button block color="danger" onClick={toggle}>
              {language === "ar" ? "امسح" : "Delete"}
            </Button>
          </th>
        </tr>
      );
    }
    return (
      <tr key={user.user_id}>
        <CustomModal
          isOpen={isOpen}
          language={language}
          toggle={toggle}
          className="text-danger"
          actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
          handleAction={() => handleDelete(user)}
          title={
            language === "ar"
              ? "هل انت متاكد تريد مسح هزا المستخدم"
              : "Are you sure want to delete this user?"
          }
        />
        <th>{user.full_name}</th>
        <th>{user.email}</th>
        <th>{user.user_type}</th>
        <th>
          <Button
            onClick={() => setEditMode(user.user_id)}
            block
            color="primary"
          >
            {language === "ar" ? "تعديل " : "Edit type"}
          </Button>
        </th>
        <th>
          <Button block color="danger" onClick={toggle}>
            {language === "ar" ? "امسح" : "Delete"}
          </Button>
        </th>
      </tr>
    );
  };

  return (
    <>
      <DashboardNav token={token} language={language} />
      {isLoading && <SpinnerComponent />}
      <Container>
        <Table>
          <thead>
            <tr>
              <th>{language === "ar" ? "الاسم الكامل" : "Full name"}</th>
              <th>{language === "ar" ? "البريد الاكتروني" : "Email"}</th>
              <th>{language === "ar" ? "نوع المستخدم" : "User type"}</th>
              <th>{language === "ar" ? "تعديل نوع المستخدم" : "Edit type"}</th>
              <th>{language === "ar" ? "امسح المستخدم" : "Delete user"}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return renderUser(user);
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Users;
