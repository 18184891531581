import React from "react";

const Donation = () => (
  <form
    action={process.env.REACT_APP_DONATION_ACTION}
    method="post"
    target="_blanket"
  >
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input
      type="hidden"
      name="hosted_button_id"
      value={process.env.REACT_APP_DONATION_ID}
    />
    <input
      style={{ outline: "none" }}
      type="image"
      src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif"
      border="0"
      name="submit"
      title="PayPal - The safer, easier way to pay online!"
      alt="Donate with PayPal button"
    />
    <img
      alt=""
      border="0"
      src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
      width="1"
      height="1"
    />
  </form>
);

export default Donation;
