import axios from "axios";
import React, { Component } from "react";
import { Container } from "reactstrap";
import FormComponent from "../FormComponent";
import Notification from "../Notification";
import {
  user,
  baseApiUrl,
  validateForm,
  setAxiosAuthHeader,
} from "../../helpers";
import firebase from "../../firebase-config";
import SpinnerComponent from "../Spinner";
import DashboardNav from "../Dashboard/DashboardNav";
import styled from "@emotion/styled";
import { Redirect } from "react-router";

const MainTitle = styled.h3`
  ${({ language }) => language === "ar" && `text-align: right`}
`;

class AddSharedArticle extends Component {
  state = {
    data: [],
    title: "",
    theme: "",
    error: {},
    order: null,
    image: null,
    subtitle: "",
    description: "",
    isLoading: false,
    goToThisPath: "",
    writer: user(this.props.token).fullName,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { language } = this.props;
    try {
      const response = await axios.get(
        `${baseApiUrl()}/${this.props.endpoint}?lang=${language}`
      );
      this.setState({ data: response.data || [] });
    } catch (error) {
      console.error("error ====", error);
    }
  };

  handleUpload = (data) => {
    const { image } = this.state;
    const updatedImageName = `${Date.now().toString()}-${image.name}`;

    const uploadTask = firebase
      .storage()
      .ref(`images/${updatedImageName}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
        this.setState({ isLoading: false });
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("images")
          .child(updatedImageName)
          .getDownloadURL()
          .then(async (url) => {
            const response = await axios.post(
              `${baseApiUrl()}/${this.props.endpoint}`,
              {
                ...data,
                imageUrl: url,
                imageName: updatedImageName,
              }
            );
            if (response.data) {
              this.setState({ goToThisPath: `/${this.props.endpoint}` });
            }
          });
      }
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { description, title } = this.state;

    const callBack = (error) => {
      this.setState({ error });
    };
    if (
      !validateForm({
        title,
        callBack,
        image: true,
        description,
      })
    ) {
      return;
    }

    try {
      setAxiosAuthHeader(this.props.token);
      const data = {
        title: this.state.title,
        theme: this.state.theme,
        subtitle: this.state.subtitle,
        language: this.props.language,
        order: this.state.data.length + 1,
        description: this.state.description,
        writer: this.state.writer || user(this.props.token).fullName,
        imageUrl: "",
        imageName: "",
      };
      this.setState({ isLoading: true });
      if (this.state.image) {
        this.handleUpload(data);
      } else {
        const response = await axios.post(
          `${baseApiUrl()}/${this.props.endpoint}`,
          data
        );
        if (response.data) {
          this.setState({ goToThisPath: `/${this.props.endpoint}` });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, error: {} });
  };

  handleImage = (e) => {
    e.preventDefault();
    this.setState({ image: e.target.files[0], error: {} });
  };

  render() {
    const { error, isLoading, goToThisPath } = this.state;
    const { language, token } = this.props;

    return (
      <>
        <DashboardNav token={token} language={language} />
        {goToThisPath && <Redirect to={goToThisPath} />}
        <Container className="my-3">
          {isLoading && <SpinnerComponent />}
          {error.title && (
            <Notification
              title={error.title}
              type={error.type}
              message={error.message}
            />
          )}
          <MainTitle language={language} className="display-5">
            {this.props.title}
          </MainTitle>
          <FormComponent
            token={token}
            language={language}
            onSubmit={this.onSubmit}
            handleImage={this.handleImage}
            handleChange={this.handleChange}
          />
        </Container>
      </>
    );
  }
}

export default AddSharedArticle;
