import React from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import styled from "@emotion/styled";

const StyledToast = styled(Toast)`
  font-size: 20px;
  ${({ language }) =>
    language &&
    `
  > div > strong {
    margin-right: 0 !important;
  }
  `}
`;

const Notification = ({ title, message, type, language }) => (
  <StyledToast language={language} className={`bg-${type}`}>
    {title && <ToastHeader>{title}</ToastHeader>}
    {message && <ToastBody>{message}</ToastBody>}
  </StyledToast>
);

export default Notification;
