import React from "react";
import { Spinner } from "reactstrap";
import styled from "@emotion/styled";

const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgb(0, 0, 0, 0.9);
`;

const SpinnerComponent = (props) => {
  return (
    <SpinnerWrapper>
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="secondary" />
      <Spinner type="grow" color="success" />
      <Spinner type="grow" color="danger" />
      <Spinner type="grow" color="warning" />
      <Spinner type="grow" color="info" />
      <Spinner type="grow" color="light" />
      <Spinner type="grow" color="dark" />
    </SpinnerWrapper>
  );
};

export default SpinnerComponent;
