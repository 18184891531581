import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  FormGroup,
  Container,
  FormFeedback,
} from "reactstrap";
import styled from "@emotion/styled";
import { baseApiUrl, getQueryByName } from "../../helpers";
import axios from "axios";
import Notification from "../Notification";
import { Redirect } from "react-router";
import SpinnerComponent from "../Spinner";

const ContactContainer = styled.div`
  padding: 50px 0;
  ${({ language }) => language === "ar" && "text-align: right"}
`;

const ContactTitle = styled.h1`
  margin: 10px 0;
  padding: 0;
  font-size: 30px;
  text-align: center;
`;

const ResetPassword = ({ language }) => {
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [goToLogin, setGoToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setToken(getQueryByName("token"));
  }, [token]);

  const validateCredential = () => {
    let isValid;
    if (!password || password.length < 5) {
      setError({
        ...error,
        password: !password
          ? language === "ar"
            ? "كلمة المرور مطلوبة!"
            : "Password is required!"
          : language === "ar"
          ? "يجب أن يكون طول كلمة المرور أطول من 5 أحرف"
          : "Password length has to be longer than 5 characters",
      });
      isValid = false;
    } else if (password !== confirmPassword) {
      setError({
        ...error,
        confirmPassword: !confirmPassword
          ? language === "ar"
            ? "تاكيد كلمة المرور مطلوب"
            : "Confirm Password is required!"
          : language === "ar"
          ? "يجب تاكيد كلمة المرور مطابقة كلمة المرور"
          : "Confirm Password has to match password",
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateCredential()) {
      return;
    }
    try {
      if (!token) {
        setError({
          title: "Error",
          type: "danger",
          message: "Woops something went wrong",
        });
        return;
      }
      if (password.length < 5) {
        return;
      }
      if (password !== confirmPassword) {
        return;
      }
      setIsLoading(true);
      const response = await axios.put(`${baseApiUrl()}/users/reset`, {
        token,
        password,
      });
      if (response.data) {
        setMessage(response.data.message[language]);
        setTimeout(() => {
          setGoToLogin(true);
        }, 5000);
      }
      setIsLoading(false);
    } catch (error) {
      setError({
        title: "Error",
        type: "danger",
        message:
          error.response &&
          error.response.data &&
          error.response.data.message[language],
      });
      setIsLoading(false);
      console.error("error ", error);
    }
    return;
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setError({});
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    setError({});
    setConfirmPassword(e.target.value);
  };

  if (message) {
    return (
      <ContactContainer language={language}>
        {goToLogin && <Redirect to="/login" />}
        <Container>
          <h3>{message}</h3>
        </Container>
      </ContactContainer>
    );
  }
  return (
    <ContactContainer language={language}>
      {isLoading && <SpinnerComponent />}
      <Container>
        <Row xs="1" sm="1">
          <Col
            sm={{ size: 6, offset: 3 }}
            style={language === "ar" ? { "margin-right": "25%" } : {}}
          >
            {error.title && (
              <Notification
                title={error.title}
                type={error.type}
                message={error.message}
              />
            )}
            <ContactTitle>
              {language === "ar" ? "اعادة ضبط كلمة المرور" : "Reset password"}
            </ContactTitle>
            <Form onSubmit={handleSubmit} method="post" action="/users">
              <FormGroup>
                <Input
                  required
                  id="Password"
                  type="password"
                  name="password"
                  onChange={handlePasswordChange}
                  invalid={Boolean(error.password)}
                  placeholder={
                    language === "ar" ? "كلمة المرور الجديدة" : "New password"
                  }
                />
                <FormFeedback>{error.password}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  type="password"
                  id="ConfirmPassword"
                  name="confirmPassword"
                  onChange={handleConfirmPasswordChange}
                  invalid={Boolean(error.confirmPassword)}
                  placeholder={
                    language === "ar"
                      ? "تاكيد كلمة المرور الجديدة"
                      : "Confirm new password"
                  }
                />
                <FormFeedback>{error.confirmPassword}</FormFeedback>
              </FormGroup>
              <Button type="submit" block color="success">
                {language === "ar" ? "ضبط" : "Reset"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </ContactContainer>
  );
};

export default ResetPassword;
