import axios from "axios";
import React, { useState, useEffect } from "react";
import HeroCarousel from "./HeroCarousel";
import { editorView, baseApiUrl, adminView, sortCallBack } from "../../helpers";

const MainHero = ({ language, token }) => {
  const [heroes, setHeroes] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseApiUrl()}/heroes?lang=${language}`
      );
      setHeroes(response.data);
      return;
    } catch (error) {
      console.log("error add cate", error);
    }
  };

  useEffect(() => {
    if (language) {
      fetchData();
    }
  }, [language]);

  if (!heroes.length) {
    return null;
  }
  const approvedHeros = heroes
    .filter(
      (hero) =>
        adminView(token) || editorView(token) || hero.hero_status === "approved"
    )
    .sort(sortCallBack);

  return <HeroCarousel language={language} items={approvedHeros} />;
};

export default MainHero;
