import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { user } from "../../helpers";
import styled from "@emotion/styled";

const CustomForm = styled(Form)`
  ${({ language }) => language === "ar" && `text-align: right`}
`;

const FormComponent = ({
  token,
  title,
  theme,
  order,
  image,
  layout,
  status,
  writer,
  imageUrl,
  onSubmit,
  subtitle,
  language,
  isEditForm,
  categoryId,
  categories,
  description,
  handleImage,
  handleChange,
  showOrderInput,
  handleRemoveImage,
}) => (
  <CustomForm language={language}>
    <FormGroup>
      <Label for="exampleTitle">
        {language === "ar" ? "العنوان" : "Title"}
      </Label>
      <Input
        type="text"
        name="title"
        id="exampleTitle"
        value={title}
        placeholder={language === "ar" ? "العنوان" : "Title"}
        onChange={handleChange}
      />
    </FormGroup>
    <FormGroup>
      <Label for="exampleSubtitle">
        {language === "ar" ? "العنوان الفرعي" : "Subtitle"}
      </Label>
      <Input
        type="text"
        name="subtitle"
        id="exampleSubtitle"
        value={subtitle}
        placeholder={language === "ar" ? "العنوان الفرعي" : "Subtitle"}
        onChange={handleChange}
      />
    </FormGroup>
    {showOrderInput && (
      <FormGroup>
        <Label for="order">{language === "ar" ? "الترتيب" : "Order"}</Label>
        <Input
          type="number"
          name="order"
          id="order"
          value={order}
          placeholder={language === "ar" ? "الترتيب" : "Order"}
          onChange={handleChange}
        />
      </FormGroup>
    )}
    <FormGroup>
      <Label for="selectTheme">
        {language === "ar" ? "اختار موضوع" : "Select theme"}
      </Label>
      <Input
        name="theme"
        type="select"
        value={theme}
        id="selectTheme"
        selected="selected"
        onChange={handleChange}
      >
        <option>{language === "ar" ? "اختار موضوع" : "Select theme"}</option>
        <option value="light">
          {language === "ar" ? "موضوع خفيف" : "Light"}
        </option>
        <option value="dark">
          {language === "ar" ? "موضوع داكن" : "Dark"}
        </option>
      </Input>
    </FormGroup>
    {layout && (
      <FormGroup>
        <Label for="selectLayout">
          {language === "ar" ? "اختار التنسيق" : "Select layout"}
        </Label>
        <Input
          name="layout"
          type="select"
          value={layout}
          id="selectLayout"
          selected="selected"
          onChange={handleChange}
        >
          <option>
            {language === "ar" ? "اختار التنسيق" : "Select layout"}
          </option>
          <option value="landscape">
            {language === "ar" ? "لاند اسكيب" : "Landscape"}
          </option>
          <option value="portrait">
            {language === "ar" ? "بورتريه" : "Portrait"}
          </option>
        </Input>
      </FormGroup>
    )}
    {user(token).userType === "admin" && isEditForm && (
      <FormGroup>
        <Label for="selectLayout">
          {language === "ar" ? "اختار الحالة" : "Select status"}
        </Label>
        <Input
          name="status"
          type="select"
          value={status}
          id="selectLayout"
          selected="selected"
          onChange={handleChange}
        >
          <option>
            {language === "ar" ? "اختار الحالة" : "Select status"}
          </option>
          <option value="approved">
            {language === "ar" ? "وافق" : "Approved"}
          </option>
          <option value="pending">
            {language === "ar" ? "قيد الانتظار" : "Pending"}
          </option>
          <option value="reject">{language === "ar" ? "رفض" : "Reject"}</option>
        </Input>
      </FormGroup>
    )}
    {categories && (
      <FormGroup>
        <Label for="exampleSelectMulti">
          {language === "ar" ? "اختار فئة" : "Select Category"}
        </Label>
        <Input
          type="select"
          name="categoryId"
          selected="selected"
          value={categoryId}
          id="exampleSelectMulti"
          onChange={handleChange}
        >
          <option>{language === "ar" ? "اختار فئة" : "Select Category"}</option>
          {categories.map((category) => (
            <option value={category.category_id}>{category.title}</option>
          ))}
        </Input>
      </FormGroup>
    )}
    {user(token).userType === "admin" && (
      <FormGroup>
        <Label for="exampleSubtitle">
          {language === "ar" ? "الكاتب" : "Writer"}
        </Label>
        <Input
          type="writer"
          name="writer"
          id="exampleWriter"
          value={writer}
          placeholder={language === "ar" ? "الكاتب" : "Writer"}
          onChange={handleChange}
        />
      </FormGroup>
    )}
    <FormGroup>
      <Label for="exampleDescription">
        {language === "ar" ? "الوصف" : "Description"}
      </Label>
      <Input
        type="textarea"
        name="description"
        id="exampleDescription"
        value={description}
        placeholder={language === "ar" ? "الوصف" : "Description"}
        onChange={handleChange}
      />
    </FormGroup>
    <FormGroup>
      <Label for="exampleImage">
        {language === "ar" ? "اضافة خلفية صورة" : "Add background Image"}
      </Label>
      <Input
        type="file"
        name="image"
        id="exampleImage"
        onChange={handleImage}
      />
    </FormGroup>
    {handleRemoveImage && (
      <>
        <Button
          color={image || imageUrl ? "warning" : "success"}
          onClick={handleRemoveImage}
        >
          {image || imageUrl
            ? language === "ar"
              ? "اخزف الصورة"
              : "Remove  image"
            : language === "ar"
            ? "لا توجد صورة"
            : "No image"}
        </Button>{" "}
      </>
    )}
    <Button color="primary" onClick={onSubmit}>
      {language === "ar" ? "ارسل" : "Submit"}
    </Button>
  </CustomForm>
);

export default FormComponent;
