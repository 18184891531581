import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "@emotion/styled";
import Donation from "../Donation";

const logo = `${process.env.PUBLIC_URL}/masio_logo.png`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const Logo = styled.div`
  width: 60%;
  justify-content: flex-start;
  display: flex;
`;

const LogoTitle = styled.p`
  margin: 0;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2;
  font-weight: 400;
  padding-bottom: 5px;
  ${({ language }) => language === "ar" && "text-align: right"};
  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

const LogoLabel = styled.span`
  margin: 0;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400;
  padding-left: 15px;
  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

const Image = styled.img`
  width: 20%;
  border-radius: 50%;
`;

const CustomLink = styled(Link)`
  display: flex;
  color: #000000;
  align-items: center;
  &:hover,
  &:focus {
    color: #000000;
    text-decoration: none;
  }
`;

const Contents = styled.div`
  width: 40%;
  justify-content: flex-end;
  display: flex;
`;

const Banner = ({ language }) => (
  <Container>
    <BannerContainer>
      <Logo>
        <div>
          <LogoTitle language={language}>
            {language === "ar"
              ? "منظمة مسرا الدولية"
              : "Masara International Organization"}
          </LogoTitle>
          <CustomLink to="/">
            <Image src={logo} alt="logo" />
            <LogoLabel>{language === "ar" ? "(ماسيو)" : "(MASIO)"}</LogoLabel>
          </CustomLink>
        </div>
      </Logo>
      <Contents>
        <Donation />
      </Contents>
    </BannerContainer>
  </Container>
);

export default Banner;
