import React from "react";
import { Redirect } from "react-router-dom";
import { user } from "../../helpers";

const RouteValidator = ({ children, roll, token }) => {
  if (
    user(token).userType === "admin" ||
    (roll && roll === user(token).userType) ||
    (roll && roll === "all" && user(token).fullName)
  ) {
    return children;
  } else {
    return <Redirect />;
  }
};

export default RouteValidator;
