import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row } from "reactstrap";
import MainHero from "../MainHero";
import Category from "../Category";
import { baseApiUrl } from "../../helpers";
import SpinnerComponent from "../Spinner";
import styled from "@emotion/styled";
import ContentsPlaceholder from "../ContentsPlaceholder";
import { editorView, adminView, sortCallBack } from "../../helpers";

const CategoryRow = styled(Row)`
  margin: 0;
`;

const Home = ({ language, token }) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseApiUrl()}/categories?lang=${language}`
      );
      setCategories(response.data);
      setIsLoading(false);
      return;
    } catch (error) {
      setIsLoading(false);
      console.log("error add cate", error);
    }
  };

  useEffect(() => {
    if (language) {
      fetchData();
    }
  }, [language]);

  return (
    <div>
      {isLoading && <SpinnerComponent />}
      <MainHero token={token} language={language} />
      {categories && !categories.length ? (
        <ContentsPlaceholder language={language} />
      ) : (
        <CategoryRow>
          {categories
            .filter(
              (cat) =>
                adminView(token) ||
                editorView(token) ||
                cat.category_status === "approved"
            )
            .sort(sortCallBack)
            .map(
              (category) =>
                category.title !== "Donation" && (
                  <Category
                    token={token}
                    language={language}
                    category={category}
                    key={category.category_id}
                    fetchCategories={fetchData}
                  />
                )
            )}
        </CategoryRow>
      )}
    </div>
  );
};

export default Home;
