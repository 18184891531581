import React from "react";
import EditSharedArticle from "../SharedArticle/EditSharedArticle";

const EditWhatWeDoArticle = (props) => (
  <EditSharedArticle
    {...props}
    endpoint="what-we-do"
    customVar="whatWeDoId"
    customId="what_we_do_id"
    customStatus="what_we_do_status"
    customStatusVar="whatWeDoStatus"
    title={
      props.language === "ar"
        ? "تعديل مقالة عن مازا نفعل"
        : "Edit what we dos article"
    }
  />
);

export default EditWhatWeDoArticle;
