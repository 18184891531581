import axios from "axios";
import styled from "@emotion/styled";
import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react";
import firebase from "../../firebase-config";
import { Container, Button } from "reactstrap";
import {
  user,
  baseApiUrl,
  editorView,
  adminView,
  setAxiosAuthHeader,
} from "../../helpers";
import SpinnerComponent from "../Spinner";
import ContentsPlaceholder from "../ContentsPlaceholder";
import CustomModal from "../CustomModale";

const HeroViewContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  ${({ language }) => language === "ar" && "text-align: right"}
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
`;

const HeroViewContents = styled.div`
  position: relative;
`;

const StyledImag = styled.img`
  max-width: 100%;
`;

const HeroDescription = styled.p`
  line-height: 35px;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    ${({ language }) => language === "ar" && "line-height: 20px;"}
  }
`;

class HeroView extends Component {
  state = {
    hero: {},
    isOpen: false,
    goHome: false,
    isLoading: false,
  };

  componentDidMount() {
    const { pathname } = window.location;
    const pathnameParts = pathname && pathname.split("/");
    const heroId = pathnameParts && pathnameParts[pathnameParts.length - 1];
    const { language } = this.props;
    this.fetchData(heroId, language);
  }

  fetchData = async (heroId, language) => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.get(
        `${baseApiUrl()}/heroes/${heroId}?lang=${language}`
      );
      this.setState({ hero: response.data, isLoading: false });
    } catch (error) {
      console.error("error add cate", error);
      this.setState({ isLoading: false });
    }
  };

  deleteHero = () => {
    const { language } = this.props;
    const { hero_id } = this.state.hero;
    axios
      .delete(`${baseApiUrl()}/heroes/${hero_id}?lang=${language}`)
      .then(() => {
        this.setState({ goHome: true });
      })
      .catch((err) => {
        console.error("Hero error", err);
        this.setState({ isLoading: false });
      });
  };

  handleDelete = async () => {
    const { image_name } = this.state.hero;
    setAxiosAuthHeader(this.props.token);
    this.setState({ isLoading: true, isOpen: false });
    if (image_name) {
      firebase
        .storage()
        .ref(`images/${image_name}`)
        .delete()
        .then(() => {
          this.deleteHero();
        })
        .catch((err) => {
          if (err.message && err.message.includes("does not exist")) {
            this.deleteHero();
          } else {
            this.setState({ isLoading: false });
          }
        });
    } else {
      this.deleteHero();
    }
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { hero, goHome, isLoading, isOpen } = this.state;
    const { language, token } = this.props;
    return (
      <HeroViewContainer language={language}>
        {isLoading && <SpinnerComponent />}
        {goHome && <Redirect to="/" />}
        {!hero.title ? (
          <ContentsPlaceholder language={language} />
        ) : (
          <Container>
            <HeroViewContents>
              <StyledImag alt={hero.image_name} src={hero.image_url} />
              <Title>{hero.title}</Title>
              {hero.subtitle && <p className="lead">{hero.subtitle}</p>}
              <HeroDescription language={language} className="lead">
                {" "}
                {hero.description}
              </HeroDescription>
            </HeroViewContents>
            {adminView(token) ||
            (editorView(token) &&
              hero.writer === user(this.props.token).fullName) ? (
              <>
                <CustomModal
                  isOpen={isOpen}
                  toggle={this.toggle}
                  language={language}
                  className="text-danger"
                  actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
                  handleAction={this.handleDelete}
                  title={
                    language === "ar"
                      ? "هل انت متاكد تريد مسح هزا الهيرو"
                      : "Are you sure want to delete this hero?"
                  }
                />
                <Button onClick={this.toggle} color="danger">
                  {language === "ar" ? "امسح" : "Delete"}
                </Button>{" "}
                <Link to={`/edit-hero/${hero.hero_id}?lang=${language}`}>
                  <Button color="primary">
                    {" "}
                    {language === "ar" ? "تعديل" : "Edit"}
                  </Button>
                </Link>
              </>
            ) : null}
          </Container>
        )}
      </HeroViewContainer>
    );
  }
}

export default HeroView;
