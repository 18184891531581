import React from "react";
import { Container } from "reactstrap";
import styled from "@emotion/styled";
import DashboardNav from "./DashboardNav";

const Wrapper = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Dashboard = ({ language, token }) => {
  return (
    <>
      <DashboardNav token={token} language={language} />
      <Wrapper token={token} language={language}>
        Content management system
      </Wrapper>
    </>
  );
};

export default Dashboard;
