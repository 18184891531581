import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "reactstrap";
import { baseApiUrl, getQueryByName } from "../../helpers";
import SpinnerComponent from "../Spinner";
import styled from "@emotion/styled";
import { Redirect } from "react-router";

const CategoryContainer = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const ActivateAccount = ({ language }) => {
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [goToLogin, setGoToLogin] = useState(false);

  const registerWithToken = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseApiUrl()}/users/activate`, {
        token,
      });
      setIsLoading(false);
      if (response.data.success) {
        setMessage(response.data.message[language]);
        setTimeout(() => {
          setGoToLogin(true);
        }, 5000);
      }
      return;
    } catch (error) {
      setIsLoading(false);
      setMessage(
        error.response &&
          error.response.data &&
          error.response.data.message[language]
      );
      console.log("error add cate", error);
    }
  };

  useEffect(() => {
    setToken(getQueryByName("token"));
    if (token) {
      registerWithToken();
    }
  }, [token]);

  return (
    <CategoryContainer>
      {goToLogin && <Redirect to="/login" />}
      {isLoading && <SpinnerComponent />}
      {message && <p>{message}</p>}
    </CategoryContainer>
  );
};

export default ActivateAccount;
