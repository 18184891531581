import React from "react";
import { Nav, Label, Input } from "reactstrap";
import styled from "@emotion/styled";

const CustomNav = styled(Nav)`
  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    margin-top: 10px;
    padding: 0;
  `}
  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
    padding: 0;
  }
`;

const Language = ({ isOpen, language, setLanguage }) => {
  const handleLanguageChange = (e) => {
    e.preventDefault();
    localStorage.setItem("language", JSON.stringify(e.target.value));
    setLanguage(e.target.value);
  };

  return (
    <CustomNav isOpen={isOpen}>
      <Label for="selectLanguage" />
      <Input
        selected
        type="select"
        value={language}
        name="selectMulti"
        id="selectLanguage"
        onChange={handleLanguageChange}
      >
        <option value="en">English</option>
        <option value="ar">العربية</option>
      </Input>
    </CustomNav>
  );
};

export default Language;
