import axios from "axios";
import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SpinnerComponent from "../Spinner";
import firebase from "../../firebase-config";
import { Container, Button } from "reactstrap";
import {
  user,
  editorView,
  baseApiUrl,
  sortCallBack,
  adminView,
  setAxiosAuthHeader,
} from "../../helpers";
import CustomModal from "../CustomModale";

const ArticleContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px 5px 10px;
  ${({ backgroundImage, language, theme }) => `
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: 52% 75%;
    ${language === "ar" && "text-align: right"};
    ${theme === "dark" && "color: #dddddd"}
    `};
  @media (min-width: 768px) {
    padding: 0 0 15px 0;
  }
`;

const ArticleContents = styled(Container)`
  position: relative;
  z-index: 1;
  padding-right: 0;
  padding-left: 0;
  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
`;

const Overflow = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0, 0, 0, 0.5);
`;

const ArticleText = styled.div`
  max-height: 100%;
  width: 100%;
  color: #000000;
  ${({ theme }) => theme === "dark" && `color: #dddddd`}
`;

const ArticleDescription = styled.p`
  line-height: 35px;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    ${({ language }) => language === "ar" && "line-height: 20px;"}
  }
`;

const Article = ({
  token,
  children,
  language,
  endpoint,
  customId,
  editLink,
  customStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if (language) {
      fetchData();
    }
  }, [language]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseApiUrl()}/${endpoint}?lang=${language}`
      );
      setArticles(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("error add cate", error);
      setIsLoading(false);
    }
  };

  const removeArticle = (articleId) => {
    axios
      .delete(`${baseApiUrl()}/${endpoint}/${articleId}?lang=${language}`)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("error add cate", error);
      });
  };

  const deleteArticle = async (article) => {
    if (article.image_name) {
      firebase
        .storage()
        .ref(`images/${article.image_name}`)
        .delete()
        .then(() => {
          removeArticle(article[customId]);
        })
        .catch((error) => {
          if (error.message && error.message.includes("does not exist")) {
            removeArticle(article[customId]);
          } else {
            setIsLoading(false);
          }
        });
    } else {
      removeArticle(article[customId]);
    }
  };

  const handleDelete = async (article) => {
    setIsOpen(false);
    setIsLoading(true);
    setAxiosAuthHeader(token);
    deleteArticle(article);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      {articles
        .filter(
          (article) =>
            adminView(token) ||
            editorView(token) ||
            article[customStatus] === "approved"
        )
        .sort(sortCallBack)
        .map((article, index) => (
          <ArticleContainer
            key={article[customId]}
            backgroundImage={article.image_url}
            language={language}
            theme={article.theme}
          >
            <ArticleContents>
              <ArticleText theme={article.theme}>
                <Title>{article.title}</Title>
                {article.subtitle && <p className="lead">{article.subtitle}</p>}
                <ArticleDescription language={language} className="lead">
                  {" "}
                  {article.description}
                </ArticleDescription>
                {adminView(token) ||
                (editorView(token) &&
                  article.writer === user(token).fullName) ? (
                  <>
                    <CustomModal
                      title={
                        language === "ar"
                          ? "هل انت متاكد تريد مسح هزه المقالة"
                          : "Are you sure want to delete this article?"
                      }
                      isOpen={isOpen}
                      toggle={toggle}
                      language={language}
                      className="text-danger"
                      actionButtonTitle={language === "ar" ? "نعم" : "Yes"}
                      handleAction={() => handleDelete(article)}
                    />
                    <Button onClick={toggle} color="danger">
                      {language === "ar" ? "امسح" : "Delete"}
                    </Button>{" "}
                    <Link
                      to={`/${editLink}/${article[customId]}?lang=${language}`}
                    >
                      <Button color="primary">
                        {" "}
                        {language === "ar" ? "تعديل" : "Edit"}
                      </Button>
                    </Link>
                  </>
                ) : null}
              </ArticleText>
            </ArticleContents>
            {article.theme === "dark" && <Overflow />}
            {index === articles.length - 1 && children}
          </ArticleContainer>
        ))}
    </>
  );
};

export default Article;
