import React from "react";
import SharedArticle from "../SharedArticle/AddSharedArticle";

const AddAboutArticle = (props) => (
  <SharedArticle
    {...props}
    endpoint="about"
    title={
      props.language === "ar"
        ? "اضافة مقالة جديدة عننا"
        : "Add about us article"
    }
  />
);

export default AddAboutArticle;
